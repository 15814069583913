<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card
    v-if="
      $store.state.schemas[provider.schema_id].definition.properties.emergency_response_types
        .enabled
    "
    id="covid"
    :border="$role == 'specialist'"
    flat
    tile
  >
    <v-card-title class="tab-title d-flex">
      <v-btn
        v-if="$vuetify.display.smAndDown"
        @click="$emit('back')"
        class="me-1 c-black"
        variant="text"
        icon
      >
        <v-icon
          icon="chevron_left"
          size="28"
        />
      </v-btn>
      <h2 class="v-card-title py-0 px-0">
        {{ $t('Changes made in response to COVID-19') }}
      </h2>
    </v-card-title>
    <v-divider />
    <v-card-text class="my-4">
      <v-row>
        <LabeledSelect
          v-if="provider.emergency_response_types"
          v-model="provider.emergency_response_types"
          id="manager_covid_response_options"
          :schema-id="provider.schema_id"
          aria-label="Add information about changes you are making in response to the COVID-19 pandemic"
          field="emergency_response_types"
          message="Add information about changes you are making in response to the COVID-19 pandemic."
          global
          tracked
        />
        <LabeledTextarea
          v-model="provider.emergency_response_description"
          id="manager_covid_response_add_additional_info"
          :schema-id="provider.schema_id"
          aria-label="Add additional information"
          field="emergency_response_description"
          message="Add additional information (optional)"
          global
          tracked
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import LabeledSelect from '@/shared/components/form/LabeledSelect.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LabeledSelect,
    LabeledTextarea,
  },

  props: {
    provider: {
      type: Object,
      default: null,
    },
  },

  emits: ['back', 'progress'],

  computed: {
    progress() {
      return this.provider.emergency_response_types.length > 0 ? 100 : 0;
    },
  },

  watch: {
    progress: {
      immediate: true,
      handler(newVal) {
        this.$emit('progress', newVal);
      },
    },
  },
};
</script>
