import Api from '@/manager/services/bright_finder';
import SubsidyQuestions from '@/shared/services/subsidy/questions.js';
import SubsidyApplicationData from '@/shared/services/subsidy/application-data.js';

export default function useSubsidyData(includeUnpublished, subsidyId, subsidyProgram) {
  const childData = ref(null);
  const childEligibilityQuestions = ref(null);
  const childOtherQuestions = ref(null);
  const childSchema = ref(null);
  const childVerificationQuestions = ref(null);
  const groupData = ref(null);
  const groupEligibilityQuestions = ref(null);
  const groupOtherQuestions = ref(null);
  const groupSchema = ref(null);
  const groupVerificationQuestions = ref(null);

  async function loadChildDataAndGroupData() {
    if (!subsidyId.value) {
      return;
    }

    const [childApplicationDataResponse, groupApplicationDataResponse] = await Promise.all([
      SubsidyApplicationData.getChildData(subsidyId.value),
      SubsidyApplicationData.getGroupData(subsidyId.value),
    ]);

    childData.value = childApplicationDataResponse.data;
    groupData.value = groupApplicationDataResponse.data;
  }

  // Load all questions for child and group based on persisted data
  async function loadChildAndGroupQuestions() {
    if (!subsidyId.value) {
      return;
    }

    const options = { includeUnpublished: includeUnpublished.value };
    const [childQuestions, groupQuestions] = await Promise.all([
      SubsidyQuestions.loadChildQuestions(subsidyId.value, options),
      SubsidyQuestions.loadGroupQuestions(subsidyId.value, options),
    ]);

    childEligibilityQuestions.value = childQuestions.childEligibilityQuestions;
    childOtherQuestions.value = childQuestions.childOtherQuestions;
    childVerificationQuestions.value = childQuestions.childVerificationQuestions;
    groupEligibilityQuestions.value = groupQuestions.groupEligibilityQuestions;
    groupOtherQuestions.value = groupQuestions.groupOtherQuestions;
    groupVerificationQuestions.value = groupQuestions.groupVerificationQuestions;
  }

  // Load questions for child based on persisted or draft data
  async function loadChildQuestions(draftData = null) {
    if (!subsidyId.value) {
      return;
    }

    const options = { includeUnpublished: includeUnpublished.value, draftData };
    const childQuestions = await SubsidyQuestions.loadChildQuestions(subsidyId.value, options);
    childEligibilityQuestions.value = childQuestions.childEligibilityQuestions;
    childOtherQuestions.value = childQuestions.childOtherQuestions;
    childVerificationQuestions.value = childQuestions.childVerificationQuestions;
  }

  // Load questions for group based on persisted or draft data
  async function loadGroupQuestions(draftData = null) {
    if (!subsidyId.value) {
      return;
    }

    const options = { includeUnpublished: includeUnpublished.value, draftData };
    const groupQuestions = await SubsidyQuestions.loadGroupQuestions(subsidyId.value, options);

    groupEligibilityQuestions.value = groupQuestions.groupEligibilityQuestions;
    groupOtherQuestions.value = groupQuestions.groupOtherQuestions;
    groupVerificationQuestions.value = groupQuestions.groupVerificationQuestions;
  }

  // Specialists in reviewer mode only use API-filtered questions in the Documents section
  // without needing to call the API for other question sections
  async function loadVerificationQuestions() {
    if (!subsidyId.value) {
      return;
    }

    const options = { includeUnpublished: includeUnpublished.value };

    childVerificationQuestions.value = (
      await SubsidyQuestions.getChildVerificationQuestions(subsidyId.value, options)
    )?.data;
    groupVerificationQuestions.value = (
      await SubsidyQuestions.getGroupVerificationQuestions(subsidyId.value, options)
    )?.data;
  }

  function loadSubsidyProgramSchemas() {
    if (!subsidyProgram.value) {
      return;
    }

    Api.public_api.organization.schema.get(subsidyProgram.value.group_schema_id, (resp) => {
      groupSchema.value = resp.data;
    });

    Api.public_api.organization.schema.get(subsidyProgram.value.child_schema_id, (resp) => {
      childSchema.value = resp.data;
    });

    // add provider schema for when we'll need to pass it through to ProviderMatch
    // https://bridgecare-finance.slack.com/archives/C02HE12EYFJ/p1728497719545089?thread_ts=1728447035.808729&cid=C02HE12EYFJ
  }

  return {
    childData,
    childEligibilityQuestions,
    childOtherQuestions,
    childSchema,
    childVerificationQuestions,
    groupData,
    groupEligibilityQuestions,
    groupOtherQuestions,
    groupSchema,
    groupVerificationQuestions,
    loadChildDataAndGroupData,
    loadChildAndGroupQuestions,
    loadChildQuestions,
    loadGroupQuestions,
    loadVerificationQuestions,
    loadSubsidyProgramSchemas,
  };
}
