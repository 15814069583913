import api from '@/shared/services/bright_finder';
import LinkedList from '@/shared/services/linked-list';
import ValueExtractor from '@/shared/services/ValueExtractor';

const BASE_VALUE = { assertion: { conditions: [], value: {} } };
const OWNER_TYPE = 'GrantProgram';
const SYNCED_MODEL = 'Grant';

/**
 * Use, organize, and validate questions for a Grant/GrantProgram
 *
 * @param {
 *  grant
 *  grantProgramId
 *  displayAll
 * } param
 *
 * @returns {{
 *  loadQuestions
 *  questions
 *  questionsLoaded
 *  validEligibilityQuestions
 *  validQuestions
 *  validVerificationQuestions
 *  validateAnswers
 * }}
 */
export default function useGrant({
  grant = ref(null),
  grantProgramId = ref(null),
  displayAll = false,
}) {
  const questions = ref([]);
  const questionsLoaded = ref(false);

  const grantProgramQuestions = computed(() => {
    return questions.value
      .filter((question) => question.synced_model === SYNCED_MODEL)
      .filter((question) => displayAll || (question.published && question.valid));
  });

  const validEligibilityQuestions = computed(() => {
    return grantProgramQuestions.value.filter((question) => question.eligibility);
  });

  const validQuestions = computed(() => {
    return grantProgramQuestions.value.filter((question) => !question.eligibility);
  });

  const validVerificationQuestions = computed(() => {
    return grantProgramQuestions.value.filter((question) => question.verification);
  });

  async function loadQuestions() {
    if (grant.value || grantProgramId.value) {
      const resp = await api.public_api.organization.question.index({
        owner_id: grant.value?.grant_program_id || grantProgramId.value,
        owner_type: OWNER_TYPE,
      });

      questions.value = LinkedList.sort(resp.data);
    }
    questionsLoaded.value = true;

    await validateAnswers();
  }

  async function validateAnswers() {
    if (!grant.value) return;

    const assertions = questions.value.map((question) => {
      if (question.conditions.length === 0) return BASE_VALUE;

      const value = ValueExtractor.extract(
        grant.value,
        question.conditions.map((condition) => condition.field),
      );
      return { assertion: { conditions: question.conditions, value } };
    });

    const { data } = await api.public_api.assertion.promiseBulkCreate(assertions);
    const { results } = data;

    if (results.length === 0) return;
    questions.value.forEach((question, index) => {
      question.valid = results[index].result;
    });
  }

  return {
    loadQuestions,
    questions,
    questionsLoaded,
    validEligibilityQuestions,
    validQuestions,
    validVerificationQuestions,
    validateAnswers,
  };
}
