<template>
  <v-card
    id="openings"
    :border="$role == 'specialist' || dialog"
    flat
    tile
  >
    <v-card-title class="tab-title d-flex align-center">
      <v-row>
        <v-col class="d-flex align-center">
          <v-btn
            v-if="$vuetify.display.smAndDown"
            @click="$emit('back')"
            :aria-label="$t('Back')"
            class="me-1 c-black"
            variant="text"
            icon
          >
            <v-icon
              icon="chevron_left"
              size="28"
            />
          </v-btn>
          <h2 class="v-card-title py-0 px-0">
            {{ $t('Openings') }}
          </h2>
        </v-col>

        <template v-if="dialog">
          <v-col class="d-flex justify-end">
            <v-btn
              @click="$emit('close')"
              :aria-label="$t('Close dialog')"
              icon="close"
              size="small"
              variant="text"
            />
          </v-col>
        </template>
      </v-row>
    </v-card-title>

    <v-divider />

    <v-card-text class="mt-4 mb-0">
      <div class="mb-6">
        <span>
          {{ $t(ADD_OPENINGS_MESSAGE) }}
        </span>
      </div>

      <v-row
        v-if="programs.length > 0"
        class="d-flex align-center"
      >
        <v-col>
          <v-chip
            v-if="openingsConfirmedAt && !anyOpenings"
            class="me-2"
            color="red"
          >
            <v-icon class="me-3"> cancel </v-icon>
            <span>
              {{ $t('No available openings') }}
            </span>
          </v-chip>

          <v-chip
            v-if="openingsConfirmedAt"
            class="fs-18 me-3"
            color="primary"
          >
            <v-icon
              class="me-2"
              color="white"
              size="26"
            >
              info
            </v-icon>
            <LongDate
              :date="openingsConfirmedAt"
              prefix="Last updated"
            />
          </v-chip>

          <v-btn
            @click="$emit('change:openings-confirmed', 'Confirmed')"
            :ripple="false"
            class="fs-18"
            data-cy="updated_openings"
            data-tid="openings_confirmed_button"
            variant="flat"
          >
            <span class="underlined">
              {{ $t('Openings shown are up to date') }}
            </span>
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mb-6">
        <v-col
          v-for="opening in openings"
          v-show="opening.id"
          :key="opening.id"
          cols="12"
          lg="6"
        >
          <ProgramOpening
            @destroy="destroy(opening)"
            @edit="edit(opening)"
            @updateCount="updateCount(opening, $event)"
            ref="opening"
            :opening="opening"
            :processing="processing"
            :programs="programs"
            :provider-id="providerId"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider
      v-if="dialog"
      class="mb-3"
    />

    <v-card-actions class="pb-6 flex-wrap">
      <template v-if="programs.length > 0">
        <v-btn
          @click="add()"
          :ripple="false"
          class="me-3 mb-2 mb-sm-0"
          color="primary"
          data-tid="add_opening_button"
          prepend-icon="add"
          size="x-large"
        >
          <span data-cy="add_opening">
            {{ $t('Add opening') }}
          </span>
        </v-btn>
      </template>

      <v-spacer />

      <template v-if="anyOpenings || !openingsConfirmedAt">
        <v-btn
          @click="closeAllOpenings()"
          :ripple="false"
          color="primary"
          data-tid="remove_all_openings"
          size="x-large"
        >
          {{ $t("I don't have any openings") }}
        </v-btn>
      </template>
    </v-card-actions>

    <ResourceDialog
      @save="updateOrCreate"
      ref="createDialog"
      :max-width="600"
      title="Add openings"
    >
      <template
        v-if="draft"
        #form
      >
        <v-row>
          <LabeledTextfield
            v-model.number="draft.count"
            cols="12"
            data-cy="openings_input"
            message="# of openings"
            min="1"
            step="1"
            type="number"
          />
          <LabeledDatePicker
            v-model="draft.start_date"
            cols="12"
            message="Date available"
          />
          <LabeledSelect
            v-model="draft.programs"
            @change="updatePrograms"
            @change:clear-all="updatePrograms([])"
            @change:select-all="updatePrograms(programs.map((program) => program.id))"
            :disabled="processing"
            :items="programs"
            :message="`For which ${terms.programs.toLowerCase()}?`"
            :multiple="true"
            cols="12"
            data-cy="select_programs_input"
            item-title="name"
            item-value="id"
            select-all
          />
          <LabeledAgeMonthsYears
            v-if="draft.programs.length > 0"
            v-model="draft.age_min"
            cols="12"
            message="Minimum age for opening"
            min="0"
          />
          <LabeledAgeMonthsYears
            v-if="draft.programs.length > 0"
            v-model="draft.age_max"
            cols="12"
            message="Maximum age for opening"
            min="1"
          />
        </v-row>
      </template>
    </ResourceDialog>
  </v-card>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/specialist/services/bright_finder';
import LabeledAgeMonthsYears from '@/shared/components/form/LabeledAgeMonthsYears.vue';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import LabeledSelect from '@/shared/components/form/LabeledSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LongDate from '@/shared/components/LongDate.vue';
import ProgramOpening from '@/shared/components/provider/ProgramOpening.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useTerms from '@/shared/composables/useTerms';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute } from 'vue-router';

const ADD_OPENINGS_MESSAGE =
  'Add openings to help families find your school. Families will get an update when any ' +
  'of the providers they have saved in the search portal add or increase their availability.';

const eventBus = useEventBus();
const { terms } = useTerms();
const route = useRoute();

const props = defineProps({
  dialog: {
    type: Boolean,
    default: false,
  },
  providerId: {
    type: String,
    default: null,
  },
  openingsConfirmedAt: {
    type: String,
    default: null,
  },
  subsidyProgramId: {
    type: String,
    default: null,
  },
});

const emit = defineEmits([
  'back',
  'change:openings-confirmed',
  'change:openings-count',
  'close',
  'destroy',
  'progress',
]);

let draft = ref(null);
let openings = ref([]);
let processing = ref(false);
let programs = ref([]);

let createDialog = ref(null);

let anyOpenings = computed(() => {
  return (
    openings.value.length > 0 && openings.value.filter((opening) => opening.count > 0).length > 0
  );
});

watch(
  () => props.providerId,
  async () => {
    await load();
  },
  { immediate: true },
);

watch(
  () => openings.value.length,
  () => {
    emit('change:openings-count', openings.value.length);
    emit('progress', props.openingsConfirmedAt || openings.value.length > 0 ? 100 : 0);
  },
);

watch(
  () => props.openingsConfirmedAt,
  () => {
    if (props.openingsConfirmedAt) {
      emit('progress', props.openingsConfirmedAt || openings.value.length > 0 ? 100 : 0);
    }
  },
  { immediate: true },
);

watch(
  () => route.query.tab,
  async (newVal) => {
    if (newVal === 'openings') {
      await load();
    }
  },
);

function add() {
  draft.value = {
    count: 1,
    programs: [],
    provider_id: props.providerId,
    start_date: new Date().toLocaleDateString('en-CA', { timeZone: 'America/Los_Angeles' }),
  };
  createDialog.value?.open();
}

async function closeAllOpenings() {
  const openingsWithAvailability = _.filter(openings.value, (opening) => opening.count > 0);
  const promises = _.map(openingsWithAvailability, (opening) =>
    updateOpening({ ...opening, count: 0 }),
  );
  await Promise.all(promises);
  loadOpenings();
  emit('change:openings-confirmed', 'Openings cleared');
}

function create() {
  processing.value = true;
  Api.provider.opening.create(
    props.providerId,
    draft.value,
    (resp) => {
      openings.value.push(resp.data);

      processing.value = false;
      createDialog.value?.close();
      eventBus.chime('Opening added');
    },
    (error) => {
      processing.value = false;
      eventBus.chime(error.response.data.errors[0]);
    },
  );
}

function destroy(opening) {
  processing.value = true;
  if (opening.id) {
    Api.provider.opening.destroy(opening.provider_id, opening.id, () => {
      eventBus.chime('Opening removed');
      loadOpenings();
      processing.value = false;
    });
  } else {
    emit('destroy');
  }
}

function edit(opening) {
  draft.value = JSON.parse(JSON.stringify(opening));
  createDialog.value?.open();
}

async function load() {
  await loadPrograms();
  loadOpenings();
}

async function loadPrograms() {
  const filters = {};
  if (props.subsidyProgramId) filters.subsidy_program_id = props.subsidyProgramId;

  const { data } = await Api.program.index(props.providerId, filters);
  programs.value = data;
}

function loadOpenings() {
  Api.provider.opening.index(props.providerId, (resp) => {
    openings.value = resp.data;
    processing.value = false;
  });
}

function setMinMaxDefaults() {
  const selectedPrograms = programs.value.filter((program) =>
    draft.value.programs.includes(program.id),
  );
  draft.value.age_min = Math.min(...selectedPrograms.map((program) => program.age_min));
  draft.value.age_max = Math.max(...selectedPrograms.map((program) => program.age_max));
}

function updateOpeningCount(updatedOpening) {
  let originalOpeningIndex = openings.value.findIndex(
    (opening) => opening.id === updatedOpening.id,
  );
  openings.value[originalOpeningIndex] = updatedOpening;
}

function updateCount(opening, $event) {
  processing.value = true;
  Api.provider.opening.update(
    opening.provider_id,
    opening.id,
    { count: $event },
    (resp) => {
      processing.value = false;
      updateOpeningCount(resp.data);
    },
    (error) => {
      loadOpenings();
      eventBus.error(error);
    },
  );
}

async function updateOpening(opening) {
  processing.value = true;
  await Api.provider.opening.update(opening.provider_id, opening.id, opening);
  createDialog.value?.close();
  loadOpenings();
}

async function updateOrCreate() {
  if (draft.value.id) {
    await updateOpening(draft.value);
  } else {
    create();
  }
}

function updatePrograms(newVal) {
  if (newVal) {
    draft.value.programs = newVal;
  }
  setMinMaxDefaults();
}
</script>
