<template>
  <v-card
    v-if="assignment.enable_comments"
    :border="border"
    :tile="tile"
    flat
  >
    <v-card-title
      v-if="titled"
      class="d-flex align-center pb-0"
    >
      <v-chip
        class="d-flex ma-2 mr-3 c-black"
        color="grey-lighten-2"
        inline
      >
        {{ topLevelComments.length || 0 }}
      </v-chip>

      {{ $t('Comments') }}
    </v-card-title>

    <v-card-text :class="cardTextClass">
      <!-- TODO: items-per-page not working (dropdown is blank); for now it's display: none -->
      <v-data-table
        v-if="topLevelComments.length"
        :headers="[]"
        :height="topLevelComments?.length >= 3 ? '750px' : ''"
        :items="topLevelComments"
        :items-per-page="3"
        class="ma-2 rounded border bg-primary-extra-light"
        disable-sort
      >
        <template #item="{ item }">
          <CommentCard
            @delete="onCommentChange"
            @replied="replied"
            @reply-active="handleActiveReply"
            :key="item.id"
            :author-colors="authorColors"
            :comment="item"
            :model-id="modelId"
            :model-type="modelType"
            :readonly="readonly"
            :replies="getReplies(item)"
            :reply-active="replyActive === item.id"
          />
        </template>
      </v-data-table>

      <div v-else-if="readonly">
        <NullState
          title="No comments found"
          hide-new-button
        />
      </div>

      <NewCommentCard
        v-if="!readonly && !replyActive"
        @created="onCommentChange"
        :model-id="modelId"
        :model-type="modelType"
        class="pt-2"
        comment-type="comment"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import '@toast-ui/editor/dist/toastui-editor.css';
import Api from '@/shared/services/bright_finder';
import colors from 'vuetify/util/colors';
import CommentCard from '@/shared/components/learning/CommentCard.vue';
import NewCommentCard from '@/shared/components/learning/NewCommentCard.vue';
import NullState from '@/shared/components/NullState.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const props = defineProps({
  assignment: {
    type: Object,
    default: null,
  },
  border: {
    type: Boolean,
    default: true,
  },
  cardTextClass: {
    type: String,
    default: '',
  },
  commentType: {
    type: String,
    default: null,
  },
  learner: {
    type: Object,
    default: null,
  },
  modelId: {
    type: String,
    default: null,
  },
  modelType: {
    type: String,
    default: null,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  tile: {
    type: Boolean,
    default: false,
  },
  titled: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['change']);

const authorColors = reactive({});

const colorOptions = ref(Object.values(colors).map((color) => color.lighten2));

const comments = ref([]);

const replyActive = ref(null);

onMounted(load);

function filterComments(allComments) {
  if (!props.commentType && !props.learner) return allComments;

  const filteredComments = allComments.filter((comment) => {
    let authorMatch = true;

    if (props.learner?.id) {
      authorMatch = comment.author_id === props.learner.id;
    }

    switch (props.commentType) {
      case 'comment':
        return authorMatch && comment.parent_comment_id === null;
      case 'reply':
        return authorMatch && comment.parent_comment_id !== null;
      default:
        return authorMatch;
    }
  });

  if (props.commentType === 'reply') {
    const topLevelCommentIds = filteredComments.map((comment) => comment.parent_comment_id);

    const replyThreads = allComments.filter(
      (comment) =>
        topLevelCommentIds.includes(comment.id) ||
        topLevelCommentIds.includes(comment.parent_comment_id),
    );

    return replyThreads;
  }

  return filteredComments;
}

function populateAuthorColors() {
  const colorOptionsLength = colorOptions.value.length;
  let colorIndex = 0;

  comments.value.forEach((comment) => {
    const authorId = comment.author_id;

    if (!authorColors[authorId]) {
      authorColors[authorId] = colorOptions.value[colorIndex];
      colorIndex += 1;

      if (colorIndex >= colorOptionsLength) colorIndex = 0;
    }
  });
}

async function onCommentChange() {
  emit('change');
  await load();
}

async function load() {
  const cohortId = route.params.cohortId || route.query.cohortId;

  const params = {
    model_type: props.modelType,
    model_id: props.modelId,
    cohort_id: cohortId,
  };

  const response = await Api.comment.index(params);
  const allComments = response?.data || [];
  comments.value = filterComments(allComments);

  populateAuthorColors();
}

async function replied() {
  replyActive.value = null;
  await onCommentChange();
}

function handleActiveReply(event) {
  if (event === null || event === undefined) {
    replyActive.value = null;
  } else {
    replyActive.value = event;
  }
}

const getReplies = (topLevelComment) =>
  comments.value.filter((comment) => comment.parent_comment_id === topLevelComment.id);

const topLevelComments = computed(() =>
  comments.value.filter((comment) => comment.parent_comment_id === null),
);

watch(([props.assignment, props.commentType, props.learner], load));
</script>

<style>
.v-data-table-footer__items-per-page {
  display: none !important;
}
</style>
