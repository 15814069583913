<template>
  <th
    @click.stop.prevent="handleClick"
    :title="statusTitle"
    class="bb-1 bt-1 br-1 bc-very-light-grey py-2"
  >
    <div
      :class="classes"
      :style="styles"
      class="c-white py-1 rounded d-flex align-center justify-center h-100pc"
    >
      {{ day }}
    </div>
  </th>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    day: {
      type: Number,
      default: null,
    },
    log: {
      type: Object,
      default: null,
    },
    queue: {
      type: Array,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    statuses: {
      type: Array,
      default: null,
    },
  },

  emits: ['click'],

  computed: {
    classes() {
      const ary = [];

      if (!this.readonly) {
        ary.push('pointer');
      }

      if (this.queued) {
        ary.push('shadowed');
      }

      return ary.join(' ');
    },

    logDay() {
      return `day_${this.day}`;
    },

    code() {
      return this.log[this.logDay];
    },

    queued() {
      return !!this.queue.find((day) => day === this.day);
    },

    status() {
      return this.statuses.find((status) => status.code === this.code);
    },

    styles() {
      if (this.status) {
        return `background-color: ${this.status.color}`;
      }
      return '';
    },

    statusTitle() {
      if (this.status) {
        return this.status.title;
      }
      return this.$t('Unknown');
    },
  },

  methods: {
    handleClick() {
      if (!this.readonly && !this.queued) {
        this.$emit('click');
      }
    },
  },
};
</script>
