<template>
  <td
    :class="cellClasses"
    :title="answer"
  >
    <div
      @click="handleClick"
      :class="classes"
      class="rounded d-flex align-center justify-center h-100pc"
    >
      <template v-if="answer != null">
        <DefinedPropertyElement
          :attribute-name="question.synced_attributes[0].name"
          :definition="schemaDefinition"
        >
          <template #default="{ properties }">
            <template v-if="properties.type == 'boolean'">
              <template v-if="answer == true">
                <span v-text="question.true_label" />
              </template>
              <template v-else-if="answer == false">
                <span v-text="question.false_label" />
              </template>
            </template>
            <template v-else>
              <span v-text="answer" />
            </template>
          </template>
        </DefinedPropertyElement>
      </template>
    </div>
  </td>
</template>

<script>
import DefinedPropertyElement from '@/shared/components/DefinedPropertyElement.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    DefinedPropertyElement,
  },

  props: {
    attendanceLog: {
      type: Object,
      default: null,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    operatingLog: {
      type: Object,
      default: null,
    },
    queuedAttendanceLogQuestionId: {
      type: String,
      default: null,
    },
    queuedAttendanceLogAnswers: {
      type: Array,
      default: () => [],
    },
    question: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    schemaDefinition: {
      type: Object,
      default: null,
    },
  },

  emits: ['click'],

  computed: {
    classes() {
      const ary = [];
      if (this.answer == null) ary.push('bg-very-light-grey');

      if (this.queued) ary.push('shadowed');

      if (!this.readonly) ary.push('pointer');

      return ary.join(' ');
    },

    cellClasses() {
      let classes = 'bb-1 py-2 br-1 bc-very-light-grey';
      if (this.invalid) {
        classes += ' bg-red';
      }
      return classes;
    },

    answer() {
      return this.attendanceLog.custom[this.field];
    },

    field() {
      return (this.question.synced_attributes[0]?.name?.split('.') || [])[1];
    },

    queued() {
      return (
        this.queuedAttendanceLogQuestionId === this.question.id &&
        this.queuedAttendanceLogAnswers.includes(this.attendanceLog.id)
      );
    },
  },

  methods: {
    handleClick() {
      if (this.readonly || this.queued) return;

      this.$emit('click');
    },
  },
};
</script>
