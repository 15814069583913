<template>
  <v-card
    id="staff"
    :border="$role == 'specialist'"
    flat
    tile
  >
    <v-card-title class="tab-title d-flex">
      <v-btn
        v-if="$vuetify.display.smAndDown"
        @click="$emit('back')"
        class="me-1 c-black"
        variant="text"
        icon
      >
        <v-icon
          icon="chevron_left"
          size="28"
        />
      </v-btn>
      <h2 class="v-card-title py-0 px-0">
        {{ $t('Staff members') }}
      </h2>
      <v-spacer />
      <v-btn
        @click="draft({})"
        color="primary"
        data-cy="add_staff_photo"
        prepend-icon="add"
      >
        {{ $t('Add') }}
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text class="py-4">
      <v-row>
        <v-col>{{ $t(STAFF_MEMBER_INTRO_TEXT) }}</v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="member in staffMembers"
          :key="member.id"
          cols="12"
        >
          <v-card
            border
            flat
          >
            <v-card-text>
              <template v-if="member.attachment_url">
                <v-row>
                  <v-col class="d-flex justify-center">
                    <div style="min-height: 250px">
                      <img
                        :src="member.attachment_url"
                        aria-label="Staff member image"
                        class="rounded mxw-100pc mxh-250"
                      />
                    </div>
                  </v-col>
                </v-row>
              </template>
              <v-row>
                <v-col>
                  <div
                    v-text="member.name"
                    class="fs-18 fw-600"
                  />
                </v-col>
              </v-row>
              <v-divider class="my-3" />
              <v-row>
                <v-col>
                  <div
                    v-text="member.bio"
                    class="fs-16 fw-500 c-black"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                @click="destroy(member.id)"
                color="red"
                data-cy="remove_staff"
                size="small"
                variant="outlined"
              >
                {{ $t('Remove') }}
              </v-btn>
              <v-spacer />
              <v-btn
                @click="draft(member)"
                color="primary"
                data-cy="edit_staff"
                size="small"
                variant="outlined"
              >
                {{ $t('Edit') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-dialog
          v-model="editorIsVisible"
          max-width="530px"
        >
          <v-card
            border
            flat
            tile
          >
            <v-card-title>
              <span>
                {{ $t(staffMember.id ? 'Edit staff member' : 'New staff member') }}
              </span>
            </v-card-title>

            <v-divider class="mb-4" />

            <v-card-text>
              <v-row>
                <v-col>
                  <dashboard
                    :props="uppyProps"
                    :uppy="uppy"
                  />
                </v-col>
              </v-row>

              <v-row>
                <LabeledTextfield
                  v-model="staffMember.name"
                  aria-label="Staff member name"
                  data-cy="staff_member_name"
                  message="Name"
                />

                <LabeledTextarea
                  v-model="staffMember.bio"
                  aria-label="Staff member bio"
                  data-cy="staff_member_bio"
                  message="Short bio"
                />
              </v-row>
            </v-card-text>

            <v-divider class="mb-4" />

            <v-card-actions>
              <v-btn
                @click="cancel()"
                size="x-large"
                variant="text"
              >
                {{ $t('Cancel') }}
              </v-btn>
              <v-spacer />
              <v-btn
                @click="save()"
                :disabled="!staffMember.name || !staffMember.bio"
                color="primary"
                data-cy="save_staff"
                size="x-large"
                variant="flat"
              >
                {{ $t('Save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { Dashboard } from '@uppy/vue';
import API from '@/shared/mixins/api';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import Uppy from '@uppy/core';
import UppyUtils from '@/shared/mixins/uppy_utils';
import XHRUpload from '@uppy/xhr-upload';

const STAFF_MEMBER_INTRO_TEXT =
  'Families are interested in learning about those who will be caring ' +
  'for their children. Upload a photo and a short bio about each of your team members.';

export default {
  compatConfig: { MODE: 3 },

  components: {
    Dashboard,
    LabeledTextarea,
    LabeledTextfield,
  },

  mixins: [API, UppyUtils],

  props: {
    providerId: {
      type: String,
      default: null,
    },
  },

  emits: ['back', 'progress'],

  data() {
    return {
      attachment: null,
      editorIsVisible: false,
      staffMember: {},
      staffMembers: [],
      uppy: null,
      uppyProps: {
        proudlyDisplayPoweredByUppy: false,
        inline: true,
        height: 200,
        locale: this.getUppyLocale(),
        width: '100%',
      },
      STAFF_MEMBER_INTRO_TEXT,
    };
  },

  watch: {
    providerId: {
      immediate: true,
      handler() {
        this.load();
      },
    },
    '$i18n.locale': {
      immediate: true,
      handler(newLocale, formerLocale) {
        if (newLocale && formerLocale && formerLocale !== newLocale) {
          this.uppyProps.locale = this.getUppyLocale();
        }
      },
    },
  },

  beforeUnmount() {
    this.uppy.close();
  },

  created() {
    this.uppy = new Uppy().use(XHRUpload, {
      endpoint: this.getUploadAPIEndpoint(),
    });

    this.uppy.on('upload-success', async (file, evt) => {
      const att = {
        file: {
          id: evt.body.id, // remove the Shrine storage prefix
          storage: 'cache',
          metadata: {
            size: file.size,
            filename: file.name,
            mime_type: file.type,
          },
        },
        category: 'image',
      };

      const resp = await this.api.member.attachment.create(att);
      if (resp?.status !== 201) return this.uppy.cancelAll();
      this.attachment = resp.data;
      return this.attachment;
    });
  },

  methods: {
    cancel() {
      this.staffMember = {};
      this.attachment = null;
      this.editorIsVisible = false;
    },

    created() {
      this.load();
    },

    async confirmAttachment(staffMemberId) {
      this.processing = true;
      const params = {
        owner_type: 'StaffMember',
        owner_id: staffMemberId,
      };
      const resp = await this.api.member.attachment.update(this.attachment.id, params);
      if (resp?.status !== 200) return;

      this.saved();
    },

    destroy(staffMemberId) {
      this.api.provider.staff_member.destroy(this.providerId, staffMemberId, () => {
        this.$eventBus.$emit('chime', 'Removed');
        this.load();
      });
    },

    draft(staffMember) {
      this.staffMember = staffMember;
      this.editorIsVisible = true;
    },

    load() {
      this.api.public_api.provider.staff_member.index(this.providerId, (resp) => {
        this.staffMembers = resp.data;
        this.$emit('progress', this.staffMembers.length > 0 ? 100 : 0);
      });
    },

    save() {
      this.api.provider.staff_member.updateOrCreate(
        this.providerId,
        this.staffMember,
        (resp) => {
          if (this.attachment) {
            this.confirmAttachment(resp.data.id);
          } else {
            this.saved();
          }
        },
        (errors) => {
          this.$eventBus.$emit('chime', errors.response.data.errors.join('. '));
        },
      );
    },

    saved() {
      this.processing = false;
      this.$eventBus.$emit('chime', 'Staff member saved!');
      this.staffMember = {};
      this.editorIsVisible = false;
      this.load();
    },
  },
};
</script>
