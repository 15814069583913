<template>
  <div>
    <v-card
      v-show="images && images.length > 0"
      id="photos"
      class="bg-super-light-blue pa-0 ma-4"
      border
      flat
    >
      <v-carousel
        v-model="image_index"
        :show-arrows="images.length > 1"
        next-icon="chevron_right"
        prev-icon="chevron_left"
        hide-delimiters
      >
        <v-carousel-item
          v-for="(image, i) in images"
          :key="i"
        >
          <div class="align-center d-flex fill-height justify-center">
            <v-img
              :src="image.url"
              max-height="500"
              eager
            />
            <div style="position: absolute; top: 20px; right: 20px">
              <v-btn
                @click="$emit('destroy', image)"
                color="secondary"
                prepend-icon="delete_forever"
              >
                <span>{{ $t('Delete') }}</span>
              </v-btn>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-card>

    <template v-if="images.length == 0">
      <p class="fs-16 c-light-black">
        {{ $t('No photos uploaded yet') }}
      </p>
    </template>
  </div>
</template>
<script setup>
import Api from '@/shared/services/bright_finder';

const props = defineProps({
  providerId: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['destroy', 'progress']);

var images = ref([]);
const image_index = ref(0);
const processing = ref(false);

defineExpose({ image_index, images, load, processing });

function load(skipToLast = false) {
  images.value = [];
  Api.public_api.provider.asset.index(props.providerId, { category: 'image' }, (resp) => {
    images.value = resp.data;
    if (skipToLast) {
      image_index.value = images.value.length - 1;
    } else {
      image_index.value = 0;
    }
    emit('progress', images.value.length > 0 ? 100 : 0);
  });
}

onMounted(load);

watch(
  () => props.providerId,
  () => {
    load(true);
  },
);
</script>
