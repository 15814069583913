import _ from 'lodash';

export const mapUpdate = (collection, update) =>
  _.map(collection, (item) => (item.id === update.id ? update : item));

export const waitForElement = (selector) =>
  new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
