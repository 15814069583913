<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card
    id="financial"
    :border="$role == 'specialist'"
    class="mb-4"
    flat
    tile
  >
    <v-card-title class="tab-title d-flex">
      <v-btn
        v-if="$vuetify.display.smAndDown"
        @click="$emit('back')"
        class="me-1 c-black"
        variant="text"
        icon
      >
        <v-icon
          icon="chevron_left"
          size="28"
        />
      </v-btn>
      <h2 class="v-card-title py-0 px-0">
        {{ $t('Financial programs') }}
      </h2>
    </v-card-title>
    <v-divider />
    <v-card-text class="my-4">
      <v-row>
        <LabeledSelect
          v-model="provider.payment_methods_accepted"
          id="manager_select_payment_types"
          :message="getLabel('payment_methods_accepted', 'What types of payment do you accept?')"
          :schema-id="provider.schema_id"
          data-cy="payment_methods_accepted"
          field="payment_methods_accepted"
          global
          tracked
        />
        <LabeledSelect
          v-model="provider.payment_subsidies_accepted"
          id="manager_select_financial_options"
          :message="
            getLabel(
              'payment_subsidies_accepted',
              'Which financial assistance options do you accept?',
            )
          "
          :schema-id="provider.schema_id"
          data-cy="manager_select_financial_options"
          field="payment_subsidies_accepted"
          global
          tracked
        />
        <LabeledSelect
          v-model="provider.payment_other_programs"
          id="manager_select_head_start_options"
          :locked="lockHeadStart"
          :message="getLabel('payment_other_programs', 'Preschool assistance programs')"
          :schema-id="provider.schema_id"
          data-cy="manager_select_head_start_options"
          field="payment_other_programs"
          global
          tracked
        />
        <LabeledTextarea
          v-model="provider.payment_other_description"
          id="manager_other_financial_options"
          :message="getLabel('payment_other_description', 'Additional payment options')"
          :schema-id="provider.schema_id"
          data-cy="manager_other_financial_options"
          field="payment_other_description"
          global
          tracked
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import LabeledSelect from '@/shared/components/form/LabeledSelect.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LabeledSelect,
    LabeledTextarea,
  },

  props: {
    provider: {
      type: Object,
      default: null,
    },
  },

  emits: ['back', 'progress'],

  computed: {
    lockHeadStart() {
      return (
        this.$role === 'manager' && this.$store.state.pages.Provider.features.enable_headstart_lock
      );
    },

    progress() {
      const dataProvided = [
        this.provider.payment_methods_accepted || [],
        this.provider.payment_subsidies_accepted || [],
        this.provider.payment_other_programs || [],
        this.provider.payment_other_description || '',
      ];

      return dataProvided.some((element) => element.length > 0) ? 100 : 0;
    },
  },

  watch: {
    progress: {
      immediate: true,
      handler(newVal) {
        return this.$emit('progress', newVal);
      },
    },
  },

  methods: {
    getLabel(property, defaultLabel) {
      return this.getProperties()[property]?.alias || defaultLabel;
    },

    getProperties() {
      return this.$store.state.schemas[this.provider.schema_id].definition.properties;
    },
  },
};
</script>
