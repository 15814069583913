<template>
  <ResourceDialog
    @save="$emit('save', draft)"
    ref="form"
    :max-width="1000"
    :processing="processing"
    :title="terms.program"
    text-class="bg-super-light-blue pt-6 pb-6"
    closeable
    fullscreen
  >
    <template
      v-if="draft"
      #form
    >
      <template v-if="!$store.state.pages.Provider.features.enable_program_lock">
        <v-card
          class="mb-4"
          border
          tile
        >
          <v-card-text>
            <v-row>
              <LabeledTextfield
                v-model="draft.name"
                :disabled="$store.state.pages.Provider.features.enable_program_lock"
                :schema-id="draft.schema_id"
                data-cy="edit_program_name"
                field="name"
                message="Name"
              />
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          class="mb-4"
          border
          tile
        >
          <v-card-text>
            <v-row>
              <LabeledTextarea
                v-model="draft.public_notice"
                :rows="1"
                :schema-id="draft.schema_id"
                field="public_notice"
                message="Public notice"
                auto-grow
              />
            </v-row>
          </v-card-text>
        </v-card>

        <LabeledSection
          :fields="['age_min', 'age_max', 'dob_start_date', 'dob_end_date']"
          :hide-top-divider="$store.state.pages.Provider.features.enable_program_lock"
          :schema-id="draft.schema_id"
          :subtitle="AGES_SUBTITLE_TEXT"
          class="mb-4"
          title="Ages"
        >
          <template #form>
            <v-row dense>
              <LabeledSimpleSelect
                v-model="draft.age_group_ids"
                :items="ageGroups"
                :schema-id="draft.schema_id"
                cols="12"
                field="age_group_ids"
                item-title="name"
                item-value="id"
                chips
                multiple
              />
            </v-row>

            <v-row>
              <LabeledAgeMonthsYears
                v-model="draft.age_min"
                :disabled="$store.state.pages.Provider.features.enable_program_lock"
                :schema-id="draft.schema_id"
                cols="6"
                field="age_min"
                md="5"
                message="Youngest age accepted"
              />

              <LabeledAgeMonthsYears
                v-model="draft.age_max"
                :disabled="$store.state.pages.Provider.features.enable_program_lock"
                :schema-id="draft.schema_id"
                cols="6"
                field="age_max"
                md="5"
                message="Oldest age accepted"
              />
            </v-row>

            <v-row>
              <LabeledDatePicker
                v-model="draft.dob_start_date"
                :disabled="$store.state.pages.Provider.features.enable_program_lock"
                :schema-id="draft.schema_id"
                cols="6"
                field="dob_start_date"
                message="Date of birth start date"
              />

              <LabeledDatePicker
                v-model="draft.dob_end_date"
                :disabled="$store.state.pages.Provider.features.enable_program_lock"
                :schema-id="draft.schema_id"
                cols="6"
                field="dob_end_date"
                message="Date of birth end date"
              />
            </v-row>
          </template>
        </LabeledSection>

        <LabeledSection
          :fields="['operating_start_date', 'operating_end_date']"
          :schema-id="draft.schema_id"
          class="mb-4"
          title="Dates"
        >
          <template #form>
            <v-row>
              <LabeledDatePicker
                v-model="draft.operating_start_date"
                :schema-id="draft.schema_id"
                cols="6"
                field="operating_start_date"
                message="Start date"
              />

              <LabeledDatePicker
                v-model="draft.operating_end_date"
                :schema-id="draft.schema_id"
                cols="6"
                field="operating_end_date"
                message="End date"
              />
            </v-row>
          </template>
        </LabeledSection>
      </template>

      <LabeledSection
        :fields="['desired_capacity', 'license_capacity']"
        :schema-id="draft.schema_id"
        :subtitle="
          `Desired capacity is the number of children you would like to ${terms.enroll.toLowerCase()}` +
          ` in this ${terms.program.toLowerCase()}. This must be equal to or less than your licensed capacity.`
        "
        class="mb-4"
        title="What is the capacity?"
      >
        <template #form>
          <v-row>
            <LabeledTextfield
              v-model="draft.desired_capacity"
              :message="schema?.definition.properties.desired_capacity.alias || 'Desired capacity'"
              :schema-id="draft.schema_id"
              cols="6"
              data-cy="desired_capacity_input"
              field="desired_capacity"
              type="number"
            />

            <template v-if="$role == ROLES.SPECIALIST || draft.license_capacity > 0">
              <LabeledTextfield
                v-model="draft.license_capacity"
                :disabled="$role != ROLES.SPECIALIST"
                :locked="$role != ROLES.SPECIALIST ? 'lock' : ''"
                :schema-id="draft.schema_id"
                cols="6"
                field="license_capacity"
                message="Licensed capacity"
                type="number"
              />
            </template>
          </v-row>
        </template>
      </LabeledSection>

      <LabeledSection
        :fields="['rate_weekly', 'is_donation_based', 'is_free']"
        :schema-id="draft.schema_id"
        class="mb-4"
        title="Cost"
      >
        <template #form>
          <template v-if="!draft.is_free">
            <div class="mb-4">
              <div class="mb-2">
                <v-row
                  v-show="draft.rate_hourly || moreRatesVisible"
                  dense
                >
                  <LabeledTextfield
                    v-model="draft.rate_hourly"
                    :schema-id="draft.schema_id"
                    class="mxw-300"
                    field="rate_hourly"
                    prepend-inner-icon="attach_money"
                    type="number"
                  />
                </v-row>

                <v-row
                  v-show="draft.rate_daily || moreRatesVisible"
                  dense
                >
                  <LabeledTextfield
                    v-model="draft.rate_daily"
                    :schema-id="draft.schema_id"
                    class="mxw-300"
                    field="rate_daily"
                    prepend-inner-icon="attach_money"
                    type="number"
                  />
                </v-row>

                <v-row
                  v-show="showWeeklyRate"
                  dense
                >
                  <LabeledTextfield
                    v-model="draft.rate_weekly"
                    :schema-id="draft.schema_id"
                    class="mxw-300"
                    field="rate_weekly"
                    prepend-inner-icon="attach_money"
                    type="number"
                  />
                </v-row>

                <v-row
                  v-show="draft.rate_monthly || moreRatesVisible"
                  dense
                >
                  <LabeledTextfield
                    v-model="draft.rate_monthly"
                    :schema-id="draft.schema_id"
                    class="mxw-300"
                    field="rate_monthly"
                    prepend-inner-icon="attach_money"
                    type="number"
                  />
                </v-row>

                <v-row
                  v-show="draft.rate_bimonthly || moreRatesVisible"
                  dense
                >
                  <LabeledTextfield
                    v-model="draft.rate_bimonthly"
                    :schema-id="draft.schema_id"
                    class="mxw-300"
                    field="rate_bimonthly"
                    prepend-inner-icon="attach_money"
                    type="number"
                  />
                </v-row>

                <v-row
                  v-show="draft.rate_biweekly || moreRatesVisible"
                  dense
                >
                  <LabeledTextfield
                    v-model="draft.rate_biweekly"
                    :schema-id="draft.schema_id"
                    class="mxw-300"
                    field="rate_biweekly"
                    prepend-inner-icon="attach_money"
                    type="number"
                  />
                </v-row>
              </div>

              <v-row v-show="!moreRatesVisible">
                <v-col>
                  <v-btn
                    @click="moreRatesVisible = true"
                    append-icon="expand_more"
                    variant="outlined"
                  >
                    {{ $t('Show all rate options') }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </template>

          <v-row dense>
            <LabeledCheckbox
              v-model="draft.is_free"
              :schema-id="draft.schema_id"
              field="is_free"
              message="Offered for free"
            />

            <LabeledCheckbox
              v-model="draft.is_donation_based"
              :schema-id="draft.schema_id"
              field="is_donation_based"
            />
          </v-row>
        </template>
      </LabeledSection>

      <LabeledSection
        :fields="['hours', 'program_types', 'regular_hours', 'season']"
        :schema-id="draft.schema_id"
        class="mb-4"
        title="Schedule"
      >
        <template #form>
          <v-row>
            <LabeledSelect
              v-model="draft.season"
              :message="
                schema?.definition.properties.season.alias ||
                `Which ${terms.programs.toLowerCase()} do you offer?`
              "
              :schema-id="draft.schema_id"
              field="season"
            />

            <LabeledSelect
              v-model="draft.program_types"
              id="manager_program_program_types"
              :message="schema?.definition.properties.program_types.alias || 'Type'"
              :schema-id="draft.schema_id"
              field="program_types"
              tracked
            />
          </v-row>

          <v-row>
            <LabeledSwitch
              v-model="draft.regular_hours"
              subtitle="The operating hours are the same as the location's hours."
              title="Same as location hours"
            />
            <v-col v-show="!draft.regular_hours">
              <v-row
                v-for="(day, index) in draft.hours"
                :key="index"
                class="mb-2"
                dense
              >
                <v-col style="padding-top: 20px; max-width: 100px">
                  <div class="c-black bc-body">
                    {{ $t($a.assets.weekdays[day.day]) }}
                  </div>
                </v-col>

                <v-col style="max-width: 100px">
                  <v-switch
                    v-model="draft.hours[index].included"
                    :disabled="
                      draft.regular_hours ||
                      !schema?.definition.properties.hours.editRoles.includes($role)
                    "
                    :label="draft.hours[index].included ? $t('Open') : $t('Closed')"
                    :readonly="draft.regular_hours"
                    color="secondary"
                    hide-details
                  />
                </v-col>

                <v-col>
                  <LabeledOpenClose
                    @change="updateDay(index, $event)"
                    :hard-lock="!schema?.definition.properties.hours.editRoles.includes($role)"
                    :model-value="[day.open, day.close]"
                    :step-value="5"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </LabeledSection>

      <LabeledSection
        :fields="['contact_email', 'contact_name', 'contact_phone', 'description']"
        :schema-id="draft.schema_id"
        class="mb-4"
        title="Additional information (optional)"
      >
        <template #form>
          <v-row>
            <LabeledTextarea
              v-model="draft.description"
              :schema-id="draft.schema_id"
              field="description"
              message="Description"
            />

            <LabeledTextfield
              v-model="draft.contact_name"
              :schema-id="draft.schema_id"
              field="contact_name"
              message="Contact name"
            />

            <LabeledTextfield
              v-model="draft.contact_email"
              :schema-id="draft.schema_id"
              field="contact_email"
              message="Contact email"
            />

            <LabeledTextfield
              v-model="draft.contact_phone"
              :schema-id="draft.schema_id"
              field="contact_phone"
              message="Contact phone"
            />
          </v-row>
        </template>
      </LabeledSection>

      <LabeledSection
        :active="customFields.length > 0"
        class="mb-4"
        title="Other"
      >
        <template #form>
          <v-row
            v-for="field in customFields"
            :key="field[0]"
          >
            <CustomField
              :field="field[0]"
              :model-value="draft"
              :schema-id="schema.id"
              :show-title="true"
              class="fs-16"
            />
          </v-row>
        </template>
      </LabeledSection>

      <v-card
        v-if="subsidyPrograms.length > 0"
        class="mb-4"
        border
        tile
      >
        <v-card-text>
          <v-row>
            <LabeledSimpleSelect
              v-model="draft.subsidy_program_id"
              :items="subsidyPrograms"
              :schema-id="draft.schema_id"
              cols="12"
              field="subsidy_program_id"
              item-title="name"
              item-value="id"
              message="Subsidy program"
              clearable
            />
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </ResourceDialog>
</template>

<script>
import CustomField from '@/shared/components/CustomField.vue';
import LabeledAgeMonthsYears from '@/shared/components/form/LabeledAgeMonthsYears.vue';
import LabeledCheckbox from '@/shared/components/form/LabeledCheckbox.vue';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import LabeledOpenClose from '@/shared/components/form/LabeledOpenClose.vue';
import LabeledSelect from '@/shared/components/form/LabeledSelect.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledSection from '@/shared/components/form/LabeledSection.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import Terms from '@/shared/mixins/terms';
import { ROLES } from '@/shared/assets/constants';

const AGES_SUBTITLE_TEXT =
  'Based on your license, you are only permitted to serve the ' +
  'age groups selected. If you have questions, please your administrator.';

export default {
  compatConfig: { MODE: 2 },

  components: {
    CustomField,
    LabeledAgeMonthsYears,
    LabeledCheckbox,
    LabeledDatePicker,
    LabeledOpenClose,
    LabeledSelect,
    LabeledSimpleSelect,
    LabeledSection,
    LabeledSwitch,
    LabeledTextfield,
    LabeledTextarea,
    ResourceDialog,
  },

  mixins: [Terms],

  props: {
    ageGroups: {
      type: Array,
      default: null,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    subsidyPrograms: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['save'],

  data() {
    return {
      customFields: [],
      draft: null,
      moreRatesVisible: false,
      schema: null,

      AGES_SUBTITLE_TEXT,
      ROLES,
    };
  },

  computed: {
    showWeeklyRate() {
      if (!this.draft) return false;

      const otherRates =
        this.draft.rate_hourly ||
        this.draft.rate_daily ||
        this.draft.rate_monthly ||
        this.draft.rate_bimonthly ||
        this.draft.rate_biweekly;

      return this.draft.rate_weekly || this.moreRatesVisible || !otherRates;
    },
  },

  methods: {
    close() {
      this.draft = null;
      this.$refs.form.close();
    },

    open(draft) {
      this.draft = JSON.parse(JSON.stringify(draft));
      this.schema = this.$store.state.schemas[this.draft.schema_id];
      this.customFields = Object.entries(
        this.schema.definition.properties.custom.properties,
      ).filter((property) => (property[1].editRoles || []).includes(this.$role));
      const title = this.draft.id ? this.draft.name : 'New program';
      this.$refs.form.open(null, title);
    },

    updateDay(index, day) {
      const [open, close] = day;
      this.draft.hours[index].open = open;
      this.draft.hours[index].close = close;
    },
  },
};
</script>
