<template>
  <td
    :title="statusTitle"
    class="bb-1 py-2 br-1 bc-very-light-grey"
  >
    <div
      @click="handleClick"
      :class="classes"
      :style="styles"
      class="rounded d-flex align-center justify-center h-100pc"
    >
      {{ code }}
    </div>
  </td>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    day: {
      type: Number,
      default: null,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    log: {
      type: Object,
      default: null,
    },
    operatingLog: {
      type: Object,
      default: null,
    },
    queue: {
      type: Array,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    statuses: {
      type: Array,
      default: null,
    },
  },

  emits: ['click'],

  computed: {
    classes() {
      const ary = [];
      if (!this.log[this.logDay]) {
        ary.push('bg-very-light-grey');
      }

      if (this.queued) {
        ary.push('shadowed');
      }

      if (this.open && !this.readonly) {
        ary.push('pointer');
      }

      return ary.join(' ');
    },

    logDay() {
      return `day_${this.day}`;
    },

    code() {
      return this.log[this.logDay];
    },

    queued() {
      return !!this.queue.find((ac) => ac.log.id === this.log.id && ac.day === this.day);
    },

    open() {
      const dayCode = this.operatingLog[this.logDay];
      if (dayCode) {
        return !this.$store.state.config.operating_statuses.find((os) => os.code === dayCode)
          .closed;
      }
      return false;
    },

    status() {
      return this.statuses.find((status) => status.code === this.code);
    },

    styles() {
      let styles = '';
      if (this.status) {
        styles += `background-color: ${this.status.color}`;
      }
      if (this.invalid) {
        styles += ' border: 2px solid #B30036';
      }
      return styles;
    },

    statusTitle() {
      if (this.status) {
        return this.status.title;
      }
      return this.$t('Unknown');
    },
  },

  methods: {
    handleClick() {
      if (this.open && !this.readonly && !this.queued) {
        this.$emit('click');
      }
    },
  },
};
</script>
