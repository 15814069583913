<template>
  <div>
    <v-bottom-sheet
      v-model="isVisible"
      :scrim="false"
      persistent
    >
      <v-sheet
        class="text-center pa-2"
        height="60px"
      >
        <template v-if="showAttendanceLogChangeQueue">
          <span class="me-2"
            >{{ queuedAttendanceLogChanges.length }}
            {{
              queuedAttendanceLogChanges.length > 1 ? $t('days selected') : $t('day selected')
            }}.</span
          >
          <v-btn
            @click="$emit('clear:attendance-log-changes')"
            class="underlined px-0 me-4"
            color="primary"
            variant="text"
          >
            <span>{{ $t('Deselect') }}</span>
          </v-btn>
          <v-btn
            @click="$emit('edit:attendance-log-status')"
            class="me-3"
            color="primary"
          >
            <span>{{ $t('Change status') }}</span>
          </v-btn>
          <v-btn
            @click="$emit('clear:attendance-log-status')"
            color="primary"
            variant="outlined"
          >
            <span>{{ $t('Clear status') }}</span>
          </v-btn>
        </template>

        <template v-else-if="showOperatingLogChangeQueue">
          <span class="me-2"
            >{{ queuedOperatingLogChanges.length }}
            {{
              queuedOperatingLogChanges.length > 1 ? $t('days selected') : $t('day selected')
            }}.</span
          >
          <v-btn
            @click="$emit('clear:operating-log-changes')"
            class="underlined px-0 me-4"
            color="primary"
            variant="text"
          >
            <span>{{ $t('Deselect') }}</span>
          </v-btn>
          <v-btn
            @click="$emit('edit:operating-log')"
            class="me-3"
            color="primary"
          >
            <span>{{ $t('Change status') }}</span>
          </v-btn>
          <v-btn
            @click="$emit('clear:operating-log-status')"
            color="primary"
            variant="outlined"
          >
            <span>{{ $t('Clear status') }}</span>
          </v-btn>
        </template>

        <template v-else-if="showAttendanceLogQuestionQueue">
          <span class="me-2"
            >{{ queuedAttendanceLogAnswers.length }}
            {{
              queuedAttendanceLogAnswers.length > 1 ? $t('fields selected') : $t('field selected')
            }}.</span
          >
          <v-btn
            @click="$emit('clear:attendance-log-question')"
            class="underlined px-0 me-4"
            color="primary"
            variant="text"
          >
            <span>
              {{ $t('Deselect') }}
            </span>
          </v-btn>
          <v-btn
            @click="$emit('edit:attendance-log-question')"
            class="me-3"
            color="primary"
          >
            <span>
              {{ $t('Update') }}
            </span>
          </v-btn>
        </template>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    queuedAttendanceLogChanges: {
      type: Array,
      default: null,
    },
    queuedAttendanceLogAnswers: {
      type: Array,
      default: null,
    },
    queuedOperatingLogChanges: {
      type: Array,
      default: null,
    },
    showAttendanceLogChangeQueue: {
      type: Boolean,
      default: false,
    },
    showAttendanceLogQuestionQueue: {
      type: Boolean,
      default: false,
    },
    showOperatingLogChangeQueue: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'clear:attendance-log-changes',
    'clear:attendance-log-question',
    'clear:attendance-log-status',
    'clear:operating-log-changes',
    'clear:operating-log-status',
    'edit:attendance-log-question',
    'edit:attendance-log-status',
    'edit:operating-log',
  ],

  computed: {
    isVisible() {
      return (
        this.showAttendanceLogChangeQueue ||
        this.showAttendanceLogQuestionQueue ||
        this.showOperatingLogChangeQueue
      );
    },
  },
};
</script>
