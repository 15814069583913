<template>
  <div class="d-flex align-center">
    <template v-if="!!numerator">
      <span
        v-text="count - numerator"
        class="fs-22 fw-600 me-2"
      />
      <span class="fs-20 me-3">{{ $t('available out of') }}</span>
    </template>

    <v-btn
      @click="update(-1)"
      ref="minus"
      :aria-label="decreaseLabel"
      class="focus-very-visible"
      size="x-small"
      variant="text"
      icon
    >
      <v-icon
        color="primary"
        icon="remove_circle"
        size="26"
      />
    </v-btn>

    <div
      v-if="count != null"
      v-text="count"
      class="d-inline-block fs-22 fw-600 ta-center w-48"
    />

    <v-btn
      @click="update(1)"
      @keyup.delete="update(-1)"
      ref="plus"
      :aria-label="increaseLabel"
      class="focus-very-visible"
      size="x-small"
      variant="text"
      icon
    >
      <v-icon
        color="primary"
        icon="add_circle"
        size="26"
      />
    </v-btn>

    <span class="ms-3 fs-20">{{ $t('total spots') }}</span>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  min: {
    type: Number,
    default: 0,
  },
  numerator: {
    type: Number,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
});

const { t } = useI18n();

const emit = defineEmits(['update:modelValue', 'updateCount']);
const count = defineModel();

const decreaseLabel = computed(() => {
  return [t('Decrease'), t(props.title), t('by'), 1].join(' ');
});

const increaseLabel = computed(() => {
  let str = [t('Increase'), t(props.title), t('by'), 1].join(' ');
  str += t(', Press Delete or Backspace to decrease by 1');
  return str;
});

function update(diff) {
  if (props.disabled) return;

  const newCount = count.value + diff;
  if (newCount >= props.min) {
    emit('update:modelValue', newCount);
    emit('updateCount', newCount);
  }
}
</script>

<style>
.h-50 {
  height: 50px;
}
.w-28 {
  width: 28px !important;
}
</style>
