import LinkedList from '@/shared/services/linked-list';
import RouterHelper from '@/shared/mixins/RouterHelper';

export default {
  mixins: [RouterHelper],

  computed: {
    selectedMonth() {
      if (this.selectedOperatingLog) {
        return [
          this.$a.assets.months[this.selectedOperatingLog.month],
          this.selectedOperatingLog.year,
        ].join(' ');
      }
    },
  },

  data() {
    return {
      attendanceLogs: [],
      attendanceLogSchema: Object.values(this.$store.state.schemas).find(
        (schema) => schema.data_type === 'AttendanceLog',
      ),
      attendanceLogQuestions: [],
      attendanceTotals: {},
      processing: false,
      providerId: null,
      operatingLogs: [],
      operatingLogSchema: Object.values(this.$store.state.schemas).find(
        (schema) => schema.data_type === 'OperatingLog',
      ),
      queuedAttendanceLogQuestionId: null,
      queuedAttendanceLogAnswers: [],
      queuedOperatingLogChanges: [],
      presentStatusCodes: this.$store.state.config.attendance_statuses
        .filter((status) => !status.absent)
        .map((status) => status.code),
      providers: [],
      programs: [],
      queuedAttendanceLogChanges: [],
      selectedOperatingLog: null,
      selectedProgram: null,
      showAttendanceLogChangeQueue: false,
      showAttendanceLogQuestionQueue: false,
      showOperatingLogChangeQueue: false,
      dailyTotals: {},
    };
  },

  methods: {
    calculateAttendanceTotals() {
      let localTotals = {};
      this.attendanceLogs.forEach((al) => {
        let total = 0;
        for (let i = 1; i <= 31; i++) {
          if (this.wasPresentOnDay(al, i)) {
            total += 1;
          }
        }
        localTotals[al.id] = total;
      });
      this.attendanceTotals = localTotals;
    },

    calculateDailyTotals() {
      for (let i = 1; i <= 31; i++) {
        if (this.dailyTotals[i]) {
          this.dailyTotals[i] = 0;
        }
      }

      this.attendanceLogs.forEach((al) => {
        for (let i = 1; i <= 31; i++) {
          this.dailyTotals[i] = this.dailyTotals[i] || 0;
          if (this.wasPresentOnDay(al, i)) {
            this.dailyTotals[i] += 1;
          }
        }
      }, this);
    },

    async changeOperatingLog(event) {
      this.changeOperatingLogById(event.operatingLogId);
    },

    async changeOperatingLogById(operatingLogId) {
      this.selectedOperatingLog = this.operatingLogs.find((ol) => ol.id === operatingLogId);
      await this.updateQuery({ operatingLogId: this.selectedOperatingLog.id });
      this.loadAttendanceLogs();
    },

    async changeProgram(evt) {
      this.selectedProgram = this.programs.find((program) => program.id === evt.programId);
      await this.updateQuery({ programId: this.selectedProgram.id });
      this.loadOperatingLogs();
    },

    changeProvider(evt) {
      this.providerId = evt.providerId;
      this.loadPrograms();
    },

    clearAttendanceLog() {
      // eslint-disable-next-line no-alert
      if (!confirm(this.$t('Are you sure you want to clear the selected attendance records?')))
        return;

      this.updateAttendanceLogsStatus(null);
    },

    clearOperatingLog() {
      // eslint-disable-next-line no-alert
      if (!confirm(this.$t('Are you sure you want to clear the selected days?'))) return;

      this.updateOperatingLog(null);
    },

    clearAttendanceChangeQueue() {
      this.queuedAttendanceLogChanges = [];
      this.showAttendanceLogChangeQueue = false;
    },

    clearOperatingLogChangeQueue() {
      this.queuedOperatingLogChanges = [];
      this.showOperatingLogChangeQueue = false;
    },

    clearAttendanceLogQuestionQueue() {
      this.queuedAttendanceLogAnswers = [];
      this.queuedAttendanceLogQuestionId = null;
      this.showAttendanceLogQuestionQueue = false;
    },

    loadAttendanceLogs() {
      this.scopedApi().attendance_log.index(
        { operating_log_id: this.selectedOperatingLog?.id },
        (resp) => {
          this.attendanceLogs = resp.data;
          this.calculateDailyTotals();
          this.calculateAttendanceTotals();
        },
      );
    },

    async loadAttendanceLogQuestions() {
      const resp = await this.api.public_api.organization.question.index({
        owner_id: this.attendanceLogSchema?.id,
        owner_type: 'Schema',
      });
      this.attendanceLogQuestions = LinkedList.sort(resp.data).filter(
        (question) => question.published,
      );
    },

    async loadOperatingLogs(operatingLog) {
      const resp = await this.scopedApi().operating_log.index({
        limit: 12,
        program_id: this.selectedProgram.id,
      });

      this.operatingLogs = resp.data;
      this.selectedOperatingLog = operatingLog || this.operatingLogs?.[0];

      if (this.selectedOperatingLog) {
        await this.updateQuery({ operatingLogId: this.selectedOperatingLog.id });
        this.loadAttendanceLogs();
      }
    },

    openAttendanceLogQuestionDialog() {
      const question = this.attendanceLogQuestions.find(
        (question) => question.id === this.queuedAttendanceLogQuestionId,
      );
      this.$refs.attendanceLogQuestionDialog.open({ question });
    },

    queueAttendanceLogChange(newVal) {
      if (this.queuedOperatingLogChanges.length === 0) {
        this.queuedAttendanceLogChanges.push(newVal);
        this.showAttendanceLogChangeQueue = true;
      }
    },

    queueAttendanceLogQuestion(newVal) {
      if (this.queuedOperatingLogChanges.length > 0 || this.queuedAttendanceLogChanges.length > 0)
        return;

      if (
        this.queuedAttendanceLogQuestionId &&
        this.queuedAttendanceLogQuestionId != newVal.questionId
      )
        return;

      this.queuedAttendanceLogQuestionId = newVal.questionId;
      this.queuedAttendanceLogAnswers ||= [];
      this.queuedAttendanceLogAnswers.push(newVal.attendanceLogId);
      this.showAttendanceLogQuestionQueue = true;
    },

    queueOperatingLogChange(day) {
      if (this.queuedAttendanceLogChanges.length === 0) {
        this.queuedOperatingLogChanges.push(day);
        this.showOperatingLogChangeQueue = true;
      }
    },

    async updateOperatingLog(status) {
      this.processing = true;
      this.queuedOperatingLogChanges.forEach((day) => {
        this.selectedOperatingLog[`day_${day}`] = status ? status.code : null;
      });
      const { data } = await this.scopedApi().operating_log.update(
        this.selectedOperatingLog.id,
        this.selectedOperatingLog,
      );
      this.selectedOperatingLog = data;
      this.loadAttendanceLogs();
      this.$refs.operatingLogStatusDialog.close();
      this.processing = false;
      this.showOperatingLogChangeQueue = false;
      this.queuedOperatingLogChanges = [];
    },

    async updateAttendanceLogsAnswers(answer) {
      this.errors = [];
      this.processing = true;
      await Promise.all(
        this.queuedAttendanceLogAnswers.map(async (attendanceLogId) => {
          await this.scopedApi().attendance_log.update(attendanceLogId, answer);
        }),
      );
      this.$refs.attendanceLogQuestionDialog.close();
      this.processing = false;
      this.loadAttendanceLogs();
      this.showAttendanceLogQuestionQueue = false;
      this.queuedAttendanceLogAnswers = [];
      this.queuedAttendanceLogQuestionId = null;
    },

    async updateAttendanceLogsStatus(status) {
      this.errors = [];
      this.processing = true;
      await Promise.all(
        this.queuedAttendanceLogChanges.map(async (ac) => {
          const newVal = {};
          newVal[`day_${ac.day}`] = status ? status.code : null;
          await this.scopedApi().attendance_log.update(ac.log.id, newVal);
        }),
      );
      this.$refs.attendanceLogStatusDialog.close();
      this.processing = false;
      this.loadAttendanceLogs();
      this.showAttendanceLogChangeQueue = false;
      this.queuedAttendanceLogChanges = [];
    },

    wasPresentOnDay(attendanceLog, day) {
      return this.presentStatusCodes.includes(attendanceLog[`day_${day}`]);
    },
  },
};
