export class ProgramParams {
  constructor(program) {
    this.program = program;
  }

  as_draft() {
    if (this.program.regular_hours) {
      return this.program;
    }
    if (!this.program.hours) {
      this.program.hours = this.default_hours();
      return this.program;
    }
    this.program.hours = this.default_hours().map(function (default_day) {
      const program_day = this.program.hours.find((day) => day.day == default_day.day);
      if (program_day) {
        program_day.included = true;
        return program_day;
      }
      default_day.included = false;
      return default_day;
    }, this);
    return this.program;
  }

  as_json() {
    this.hours = this.program.hours.filter((day) => day.included == true);

    return {
      age_group_ids: this.program.age_group_ids,
      age_max: this.program.age_max_months ? this.program.age_max : this.program.age_max * 12,
      age_min: this.program.age_min_months ? this.program.age_min : this.program.age_min * 12,
      contact_email: this.program.contact_email,
      contact_name: this.program.contact_name,
      contact_phone: this.program.contact_phone,
      custom: this.program.custom,
      description: this.program.description,
      desired_capacity: this.program.desired_capacity,
      dob_end_date: this.program.dob_end_date,
      dob_start_date: this.program.dob_start_date,
      grade_max: this.program.grade_max,
      grade_min: this.program.grade_min,
      hours: this.hours,
      id: this.program.id,
      is_donation_based: this.program.is_donation_based,
      is_free: this.program.is_free,
      license_capacity: this.program.license_capacity,
      name: this.program.name,
      operating_end_date: this.program.operating_end_date,
      operating_start_date: this.program.operating_start_date,
      period: this.program.period,
      program_types: this.program.program_types,
      public_notice: this.program.public_notice,
      rate: this.program.rate,
      rate_hourly: this.program.rate_hourly,
      rate_daily: this.program.rate_daily,
      rate_weekly: this.program.rate_weekly,
      rate_monthly: this.program.rate_monthly,
      rate_bimonthly: this.program.rate_bimonthly,
      rate_biweekly: this.program.rate_biweekly,
      regular_hours: this.program.regular_hours,
      schema_id: this.program.schema_id,
      season: this.program.season,
      subsidy_program_id: this.program.subsidy_program_id,
    };
  }

  default_hours() {
    return [
      {
        day: 0,
        open: 700,
        close: 1800,
        included: true,
      },
      {
        day: 1,
        open: 700,
        close: 1800,
        included: true,
      },
      {
        day: 2,
        open: 700,
        close: 1800,
        included: true,
      },
      {
        day: 3,
        open: 700,
        close: 1800,
        included: true,
      },
      {
        day: 4,
        open: 700,
        close: 1800,
        included: true,
      },
      {
        day: 5,
        open: 700,
        close: 1800,
        included: false,
      },
      {
        day: 6,
        open: 700,
        close: 1800,
        included: false,
      },
    ];
  }
}
