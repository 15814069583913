import _ from 'lodash';

export function getDaysInMonth(year, month) {
  return _.range(1, new Date(year, month, 0).getDate() + 1);
}

export function validateAttendance(attendanceLogs, questions, validateDays) {
  const errors = attendanceLogs
    .map((attendanceLog) => {
      const answerErrors = validateAttendanceLogAnswers(attendanceLog, questions);
      if (validateDays) return answerErrors.concat(validateAttendanceLogDays(attendanceLog));

      return answerErrors;
    })
    .flat();

  return _.compact(errors);
}

export function validateAttendanceLogDays(attendanceLog) {
  return getDaysInMonth(attendanceLog.year, attendanceLog.month).map((day) => {
    if (attendanceLog[`day_${day}`]) return null;

    return [attendanceLog.id, day].join('-');
  });
}

export function validateAttendanceLogAnswers(attendanceLog, questions) {
  return questions.map((question) => {
    if (!question.published) return null;

    const attr = question.synced_attributes[0].name.replace('custom.', '');
    const value = attendanceLog.custom[attr];

    if (value || value === 0 || value === false) return null;

    return [attendanceLog.id, question.id].join('-');
  });
}
