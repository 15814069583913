<template>
  <v-card
    border
    flat
    tile
  >
    <v-card-title class="mb-0">
      <v-row class="d-flex align-center">
        <v-col cols="10">
          <div
            v-text="programNames"
            class="fs-22"
          />
        </v-col>
        <v-col
          class="d-flex justify-end"
          cols="2"
        >
          <ActionMenu
            @click:action:delete="$emit('destroy')"
            @click:action:edit="$emit('edit')"
            :button-icon-size="20"
            :items="actionItems"
            :label="`Modify ${programNames}`"
            button-data-cy="opening-action-menu"
            button-icon="more_vert"
            left
            x-small
          />
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col class="fs-18">
          {{ $t(ageText) }}
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-if="opening.id"
          cols="12"
        >
          <VolumeInput
            v-model="opening.count"
            @updateCount="$emit('updateCount', $event)"
            :disabled="processing"
            :min="0"
            :numerator="opening.reserved_count"
            :title="programNames"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import useAgeCalculations from '@/shared/composables/useAgeCalculations';
import { useI18n } from 'vue-i18n';
import VolumeInput from '@/shared/components/form/VolumeInput.vue';

const { monthsToAgeString } = useAgeCalculations();

const props = defineProps({
  opening: {
    type: Object,
    default: null,
  },
  processing: {
    type: Boolean,
    default: false,
  },
  programs: {
    type: Array,
    default: null,
  },
  providerId: {
    type: String,
    default: null,
  },
});

defineEmits(['destroy', 'edit', 'updateCount']);
const { t } = useI18n();

const actionItems = [
  { event: 'edit', title: 'Edit', avatar: 'edit' },
  { event: 'delete', title: 'Delete', avatar: 'delete' },
];

const ageText = computed(() => {
  const ageMaxText = monthsToAgeString(props.opening.age_max);
  const ageMinText = monthsToAgeString(props.opening.age_min);

  return `${t('Ages')} ${ageMinText} - ${ageMaxText}`;
});

const programNames = computed(() => {
  return props.programs
    .filter((program) => props.opening.programs.includes(program.id))
    .map((program) => t(program.name))
    .join(` ${t('or')} `);
});
</script>
