<template>
  <v-card
    :class="reply ? 'bg-grey-lighten-5' : 'bg-white'"
    class="ma-3"
    border
    flat
  >
    <v-row class="pa-2 pb-0">
      <v-col
        class="d-flex align-center"
        cols="11"
        sm="10"
      >
        <v-avatar
          :color="authorColors[comment.author_id]"
          class="ma-2 mr-3"
          size="36px"
        >
          <span class="pa-2 font-weight-bold text-white">
            {{ userInitials(comment.author_name) }}
          </span>
        </v-avatar>

        <div>
          <v-card-title class="pa-0 fw-400 fs-16">
            {{ comment.author_name }}
          </v-card-title>

          <v-card-subtitle class="pa-0 fs-13">
            <LongDateTime :date="comment.updated_at" />
          </v-card-subtitle>
        </div>
      </v-col>

      <v-col
        v-if="editable"
        class="pa-5 ta-right"
        cols="1"
        data-testid="comment-action-menu"
        sm="2"
      >
        <ActionMenu
          @click:action:delete="destroy"
          :button-icon-size="22"
          :items="[
            {
              title: 'Delete',
              avatar: 'delete',
              event: 'delete',
            },
          ]"
          button-icon="more_vert"
          left
          small
        />
      </v-col>
    </v-row>

    <v-card-text>
      <MarkdownContent
        :class="{ 'text-medium-emphasis': isDeleted }"
        :content="commentText"
      />

      <v-row
        v-if="!reply"
        class="mt-3 mb-1 mx-2 pa-0"
      >
        <v-expansion-panels
          :disabled="!replies?.length"
          flat
          tile
        >
          <v-expansion-panel :readonly="!replies?.length">
            <v-expansion-panel-title class="pa-0 pr-2">
              <v-chip
                class="d-flex ma-2 mr-3 c-black"
                color="grey-lighten-2"
                inline
              >
                {{ replies?.length || 0 }}
              </v-chip>

              {{ $t('Replies') }}
            </v-expansion-panel-title>

            <v-expansion-panel-text class="pa-0">
              <CommentCard
                v-for="replyComment in replies"
                @delete="$emit('delete')"
                :key="replyComment.id"
                :author-colors="authorColors"
                :comment="replyComment"
                :model-id="modelId"
                :model-type="modelType"
                :replies="[]"
                reply
              />
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-card-text>

    <v-card-actions
      v-if="!readonly && !reply && !isDeleted"
      class="pt-0"
      data-testid="comment-reply-action"
    >
      <v-row class="mx-6 pb-4">
        <v-col
          class="pa-0"
          cols="12"
        >
          <v-row>
            <v-btn
              @click="toggleReplyActive"
              class="my-3"
              color="primary"
              variant="text"
            >
              {{ $t(replyActive ? 'Cancel' : 'Reply') }}
            </v-btn>
          </v-row>

          <v-row v-if="replyActive">
            <v-col
              class="pa-0"
              cols="12"
            >
              <NewCommentCard
                @created="$emit('replied', $event)"
                :model-id="modelId"
                :model-type="modelType"
                :parent-comment-id="comment.id"
                comment-type="reply"
                style="padding-left: 0 !important; padding-right: 0 !important"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import NewCommentCard from '@/shared/components/learning/NewCommentCard.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import useEventBus from '@/shared/composables/useEventBus';

const emit = defineEmits(['delete', 'replied', 'replyActive']);
const props = defineProps({
  authorColors: {
    type: Object,
    default: null,
  },
  comment: {
    type: Object,
    default: null,
  },
  modelId: {
    type: String,
    default: null,
  },
  modelType: {
    type: String,
    default: null,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  replies: {
    type: Array,
    default: null,
  },
  reply: {
    type: Boolean,
    default: false,
  },
  replyActive: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const eventBus = useEventBus();
const store = useStore();

const currentMemberId = computed(() => store.state.profile.id);
const currentMemberIsSpecialist = computed(() => store.state.profile.is_specialist || false);
const commentText = computed(() => {
  if (!isDeleted.value) return props.comment.text;
  return isReply.value ? t('This reply has been deleted.') : t('This comment has been deleted.');
});
const isDeleted = computed(() => props.comment.deleted_at !== null);
const isReply = computed(() => Boolean(props.comment.parent_comment_id));
const currentMemberIsAuthor = computed(() => props.comment.author_id === currentMemberId.value);
const editable = computed(
  () => !isDeleted.value && (currentMemberIsAuthor.value || currentMemberIsSpecialist.value),
);

async function destroy() {
  if (confirm(t('Are you sure you want to remove this comment? This cannot be undone.'))) {
    const resp = await Api.comment.destroy(props.comment.id, {
      model_id: props.modelId,
      model_type: props.modelType,
    });

    if (resp.status === 200) {
      eventBus.chime('Comment deleted.');
      emit('delete');
    } else {
      eventBus.chime('An error occurred');
    }
  }
}

function toggleReplyActive() {
  if (props.replyActive) {
    emit('replyActive');
  } else {
    emit('replyActive', props.comment.id);
  }
}

function userInitials(name) {
  return name
    .split(' ')
    .map((n) => n[0])
    .join('')
    .slice(0, 2);
}
</script>

<style>
.v-expansion-panel-text__wrapper {
  padding: 0 !important;
}
</style>
