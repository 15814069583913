<template>
  <v-card
    class="px-2"
    flat
  >
    <v-card-title
      v-if="title"
      class="fs-18 px-0 pt-1"
    >
      {{ title }}
    </v-card-title>

    <v-card-text
      :id="editorId()"
      class="w-100pc br-0 px-0"
    />

    <v-card-actions class="px-0">
      <v-btn
        @click="create"
        color="primary"
        block
      >
        {{ $t(buttonText) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import '@toast-ui/editor/dist/toastui-editor.css';

import Api from '@/shared/services/bright_finder';
import Editor from '@toast-ui/editor';
import useEventBus from '@/shared/composables/useEventBus';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { waitForElement } from '@/shared/services/utils';

// setMarkdown() breaks when editor is reactive: https://github.com/nhn/tui.editor/issues/2138
let editor = null;

const eventBus = useEventBus();
const route = useRoute();
const store = useStore();
const { t } = useI18n();

const props = defineProps({
  commentType: {
    type: String,
    default: null,
  },
  modelId: {
    type: String,
    default: null,
  },
  modelType: {
    type: String,
    default: null,
  },
  parentCommentId: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['created']);

const currentMemberId = ref(store.state.profile.id);

const buttonText = computed(() => {
  switch (props.commentType) {
    case 'comment':
      return 'Post comment';
    case 'reply':
      return 'Post reply';
    default:
      return '';
  }
});

const title = computed(() => {
  switch (props.commentType) {
    case 'comment':
      return 'Post comment';
    case 'reply':
      return 'Reply to comment';
    default:
      return '';
  }
});

async function create() {
  const text = editor.getMarkdown().replaceAll('\\_', '_');

  if ([null, ''].includes(text)) {
    eventBus.chime('Comment requires text');
    return;
  }

  const params = {
    model_type: props.modelType,
    model_id: props.modelId,
    author_id: currentMemberId.value,
    parent_comment_id: props.parentCommentId,
    text,
    cohort_id: route.params.cohortId || route.query.cohortId,
  };

  const response = await Api.comment.create(params);
  if (response.status === 200) {
    eventBus.chime(`Posted ${props.commentType}`);
    editor.setMarkdown('', true);
    emit('created');
  } else {
    eventBus.chime('An error occurred');
  }
}

function editorId() {
  // Theoretically, only one editor should be displayed at a time
  // But to avoid a conflict via race condition, we'll make the id unique
  let id = `editor-${props.commentType}`;
  if (props.parentCommentId) id += `-${props.parentCommentId}`;
  return id;
}

function openEditor() {
  const id = `#${editorId()}`;

  waitForElement(id).then(() => {
    editor = new Editor({
      autofocus: false,
      el: document.querySelector(id),
      height: '200px',
      initialEditType: 'wysiwyg',
      // Bug: https://github.com/nhn/tui.editor/issues/3206
      initialValue: ' ',
      placeholder: t('Type message here'),
      previewStyle: 'vertical',
      toolbarItems: [['heading', 'bold', 'italic'], ['hr', 'quote'], ['ul', 'ol'], ['link']],
    });
  });
}

onMounted(openEditor);
</script>
