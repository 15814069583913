<template>
  <v-card
    id="enrollment"
    :border="$role == 'specialist'"
    flat
    tile
  >
    <v-card-title class="tab-title d-flex">
      <v-btn
        v-if="$vuetify.display.smAndDown"
        @click="$emit('back')"
        class="me-1 c-black"
        variant="text"
        icon
      >
        <v-icon
          icon="chevron_left"
          size="28"
        />
      </v-btn>
      <h2 class="v-card-title py-0 px-0">
        {{ $t('Enrollment') }}
      </h2>
    </v-card-title>
    <v-divider />
    <v-card-text class="my-4">
      <p class="mb-4">
        {{ $t(description || 'How many children are currently enrolled in each age group?') }}
      </p>
      <div class="mt-0 d-none d-md-flex">
        <div class="d-inline-block w-250" />
        <div class="d-inline-block w-150 ta-center">
          {{ $t('# of enrolled') }}
        </div>
      </div>
      <div
        v-for="program in programs"
        :key="program.id"
        class="mt-4"
      >
        <div class="d-inline-block w-250 h-56 va-top pt-4">
          {{ $t(program.name) }}
        </div>
        <div class="d-inline-block w-150">
          <v-text-field
            v-model="program.enrolled_count"
            @change="save(program)"
            :aria-label="$t('number')"
            min="0"
            type="number"
            variant="filled"
            hide-details
          />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import API from '@/shared/mixins/api';

export default {
  compatConfig: { MODE: 2 },

  mixins: [API],

  props: {
    description: {
      type: String,
      default: null,
    },
    providerId: {
      type: String,
      default: null,
    },
  },

  emits: ['back', 'progress'],

  data() {
    return {
      programs: [],
    };
  },

  watch: {
    providerId: {
      immediate: true,
      handler() {
        this.load();
      },
    },
  },

  methods: {
    async load() {
      const { data } = await this.api.program.index(this.providerId);

      this.programs = data;
      const progress = this.programs.every((program) => program.enrolled_count != null) ? 100 : 0;
      this.$emit('progress', progress);
    },

    save(program) {
      this.api.program.update(
        this.providerId,
        program.id,
        { enrolled_count: program.enrolled_count },
        () => {
          this.load();
          this.$eventBus.$emit('chime', 'Enrollment updated');
        },
        (error) => {
          this.$eventBus.$emit('longChime', error.response.data.errors[0]);
          this.load();
        },
      );
    },
  },
};
</script>
