import { ENROLLMENT_STATUSES as STATUSES, ROLES } from '@/shared/assets/constants';

const STRICT_INVALID_STATUSES = {
  Placed: [STATUSES.ENROLLED, STATUSES.UNENROLLED, STATUSES.GRADUATED],
};

const PUBLISHES_NOTICE_STATUSES = [
  STATUSES.ACCEPTED,
  STATUSES.ENROLLED,
  STATUSES.GRADUATED,
  STATUSES.OFFERED,
  STATUSES.UNENROLLED,
  STATUSES.WITHDRAWN,
];

const ENROLLMENT_AGENT_STATUSES = [STATUSES.OFFERED, STATUSES.ACCEPTED, STATUSES.WITHDRAWN];

/**
 * @deprecated This mixin is replaced by useEnrollmentStatuses.js
 * @example ```vue
 * <script setup>
 * import useEnrollmentStatuses from '@/shared/composables/useEnrollmentStatuses;
 *
 * const { allStatuses } = useEnrollmentStatuses();
 * </script>
 * ```
 */
export default {
  computed: {
    allStatuses() {
      return this.pendingStatuses.concat(this.removedStatuses).concat(this.enrolledStatuses);
    },

    enrolledStatuses() {
      return this.filterExcludedEnrollmentStatuses([
        {
          text: this.$store.state.config.terms.enrolled,
          value: STATUSES.ENROLLED,
          term: STATUSES.ENROLLED.toLowerCase(),
        },
        {
          text: this.$store.state.config.terms.unenrolled,
          value: STATUSES.UNENROLLED,
          term: STATUSES.UNENROLLED.toLowerCase(),
        },
        {
          text: this.$store.state.config.terms.graduated,
          value: STATUSES.GRADUATED,
          term: STATUSES.GRADUATED.toLowerCase(),
        },
      ]);
    },

    pendingStatuses() {
      return this.filterExcludedEnrollmentStatuses([
        {
          text: this.$store.state.config.terms.selected,
          value: STATUSES.SELECTED,
          term: STATUSES.SELECTED.toLowerCase(),
        },
        {
          text: this.$store.state.config.terms.proposed,
          value: STATUSES.PROPOSED,
          term: STATUSES.PROPOSED.toLowerCase(),
        },
        {
          text: this.$store.state.config.terms.placed,
          value: STATUSES.PLACED,
          term: STATUSES.PLACED.toLowerCase(),
        },
        {
          text: this.$store.state.config.terms.offered,
          value: STATUSES.OFFERED,
          term: STATUSES.OFFERED.toLowerCase(),
        },
        {
          text: this.$store.state.config.terms.waitlisted,
          value: STATUSES.WAITLISTED,
          term: STATUSES.WAITLISTED.toLowerCase(),
        },
        {
          text: this.$store.state.config.terms.accepted,
          value: STATUSES.ACCEPTED,
          term: STATUSES.ACCEPTED.toLowerCase(),
        },
      ]);
    },

    releasedCurrentStatuses() {
      return this.filterExcludedEnrollmentStatuses([
        {
          text: this.$store.state.config.terms.offered,
          value: STATUSES.OFFERED,
          term: STATUSES.OFFERED.toLowerCase(),
        },
        {
          text: this.$store.state.config.terms.accepted,
          value: STATUSES.ACCEPTED,
          term: STATUSES.ACCEPTED.toLowerCase(),
        },
      ]);
    },

    releasedNewStatuses() {
      return this.filterExcludedEnrollmentStatuses([
        {
          text: this.$store.state.config.terms.declined,
          value: STATUSES.DECLINED,
          term: STATUSES.DECLINED.toLowerCase(),
        },
        {
          text: this.$store.state.config.terms.selected,
          value: STATUSES.SELECTED,
          term: STATUSES.SELECTED.toLowerCase(),
        },
        {
          text: this.$store.state.config.terms.withdrawn,
          value: STATUSES.WITHDRAWN,
          term: STATUSES.WITHDRAWN.toLowerCase(),
        },
      ]);
    },

    removedStatuses() {
      return this.filterExcludedEnrollmentStatuses([
        {
          text: this.$store.state.config.terms.declined,
          value: STATUSES.DECLINED,
          term: STATUSES.DECLINED.toLowerCase(),
        },
        {
          text: this.$store.state.config.terms.withdrawn,
          value: STATUSES.WITHDRAWN,
          term: STATUSES.WITHDRAWN.toLowerCase(),
        },
        {
          text: this.$store.state.config.terms.standby,
          value: STATUSES.STANDBY,
          term: STATUSES.STANDBY.toLowerCase(),
        },
      ]);
    },
  },

  methods: {
    filterExcludedEnrollmentStatuses(statuses) {
      if (this.$role === ROLES.MANAGER) {
        return statuses.filter(
          (status) =>
            !this.$store.state.config.excluded_provider_enrollment_statuses.includes(status.value),
        );
      }
      if (this.$role === ROLES.SPECIALIST) {
        return statuses.filter(
          (status) =>
            !this.$store.state.config.excluded_specialist_enrollment_statuses.includes(
              status.value,
            ),
        );
      }
      return statuses;
    },

    getStatusText(statusValue) {
      return this.allStatuses.find((status) => status.value === statusValue)?.text || statusValue;
    },

    getValidStatusChanges(status, strict, profile) {
      if (profile?.org_enrollments_edit) {
        if (!strict) return this.allStatuses;

        return this.allStatuses.map((status) => ({
          ...status,
          disabled: STRICT_INVALID_STATUSES[status]?.includes(status.value),
        }));
      }
      if (profile?.org_enrollments_agent) return ENROLLMENT_AGENT_STATUSES;
      return [];
    },

    isPublishNoticeStatus(status) {
      return PUBLISHES_NOTICE_STATUSES.includes(status);
    },
  },
};
