<template>
  <v-card
    v-if="enabled"
    border
    flat
    tile
  >
    <v-card-title
      class="fs-18"
      dense
    >
      <span>{{ $t(title) }}</span>
    </v-card-title>

    <v-divider />

    <v-card-text>
      <div
        v-if="subtitle"
        class="fs-16 c-light-black mb-2"
      >
        {{ $t(subtitle) }}
      </div>
      <slot name="form" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    active: Boolean,
    fields: {
      type: Array,
      default: null,
    },
    hideTopDivider: {
      type: Boolean,
      default: false,
    },
    schemaId: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },

  computed: {
    enabled() {
      return this.active || (this.fields || []).some((field) => this.getProp(field).enabled);
    },
  },

  methods: {
    getProp(field) {
      if (!field) return null;

      return this.namespace(this.$store.state.schemas[this.schemaId].definition.properties, field);
    },

    namespace(object, path) {
      return path.split('.').reduce((value, index) => value[index], object);
    },
  },
};
</script>
