<template>
  <div>
    <v-row dense>
      <v-col
        class="py-0"
        cols="12"
      >
        <PublicNotice
          v-if="program.public_notice"
          :public-notice="program.public_notice"
          small
        />

        <div
          v-if="enrollmentCount != null"
          class="d-flex align-start my-3"
        >
          <v-icon
            class="me-3"
            color="primary"
            size="24"
          >
            chair_alt
          </v-icon>
          <template v-if="$role == 'specialist'">
            <span
              @click="updateQuery({ tab: 'students', programId: program.id })"
              class="c-primary underlined pointer"
            >
              {{ enrollmentCount }} {{ $t('enrolled') }}
            </span>
          </template>
          <template v-else>
            <router-link
              :to="{
                name: 'EnrollmentIndex',
                query: { providerId: $route.params.providerId, programId: program.id },
              }"
            >
              {{ enrollmentCount }} {{ $t('enrolled') }}
            </router-link>
            <!-- replace 'enrolled' with termed version? -->
          </template>

          <template v-if="seatCount != null && seatCount > 0">
            <div class="ms-2">
              <span class="me-1">/</span>
              {{ seatCount }} {{ $t('seats') }}
            </div>
          </template>
        </div>

        <div class="d-flex align-start my-3">
          <v-icon
            class="me-3"
            color="primary"
            size="24"
          >
            child_care
          </v-icon>
          <div
            v-if="program.grade_max"
            class="d-inline-block fs-16 fw-400"
          >
            {{ $t('Grades') }}: {{ gradeMinText }} - {{ gradeMaxText }}
          </div>
          <div
            v-if="!program.grade_max && program.age_max"
            class="d-inline-block fs-16 fw-400"
          >
            {{ $t('Ages:') }} {{ ageMinText }} - {{ ageMaxText }}
          </div>
          <div
            v-if="!program.grade_max && !program.age_max"
            class="d-inline-block fs-16 fw-400"
          >
            {{ $t('Age groups not available') }}
          </div>
        </div>

        <div class="d-flex align-start my-3">
          <v-icon
            class="me-3"
            color="primary"
            size="24"
          >
            date_range
          </v-icon>

          <div
            v-if="program.hours_description && program.hours_description.length > 0"
            class="d-inline-block"
          >
            <div
              v-if="program.season"
              v-text="$t(program.season)"
              class="fs-16 fw-400 mb-1"
            />
            <HoursChunks :chunks="program.hours_chunks" />
          </div>

          <div
            v-else
            class="d-inline-block"
          >
            <div class="fs-16 fw-400">
              <span
                v-if="program.season"
                v-text="$t(program.season) + ' - '"
              />
              <span>{{ $t('same as location hours') }}</span>
            </div>
          </div>

          <div
            v-if="!program.regular_hours && !program.hours_description"
            class="d-inline-block c-light-black fs-16 fw-400"
          >
            <div
              v-if="program.season"
              v-text="$t(program.season)"
              class="fs-16 fw-400"
            />
            <div
              v-else
              v-t="'No program schedule information'"
            />
          </div>
        </div>

        <div class="d-flex align-start my-3">
          <v-icon
            class="me-3"
            color="primary"
            size="24"
          >
            monetization_on
          </v-icon>

          <div
            v-if="program.is_free"
            class="d-inline-block"
          >
            <span
              v-t="'Free'"
              class="fw-400 fs-16"
            />
            <span
              v-if="program.is_donation_based"
              class="fs-16 fw-400"
              >{{ $t('- donations accepted') }}</span
            >
          </div>

          <div
            v-else
            class="d-inline-block"
          >
            <template v-if="hasRates">
              <div
                v-if="program.rate_hourly"
                class="fs-16 fw-400"
              >
                {{ currency(program.rate_hourly) }} {{ $t('paid hourly') }}
              </div>
              <div
                v-if="program.rate_daily"
                class="fs-16 fw-400"
              >
                {{ currency(program.rate_daily) }} {{ $t('paid daily') }}
              </div>
              <div
                v-if="program.rate_weekly"
                class="fs-16 fw-400"
              >
                {{ currency(program.rate_weekly) }} {{ $t('paid weekly') }}
              </div>
              <div
                v-if="program.rate_monthly"
                class="fs-16 fw-400"
              >
                {{ currency(program.rate_monthly) }} {{ $t('paid monthly') }}
              </div>
              <div
                v-if="program.rate_bimonthly"
                class="fs-16 fw-400"
              >
                {{ currency(program.rate_bimonthly) }} {{ $t('paid twice a month') }}
              </div>
              <div
                v-if="program.rate_biweekly"
                class="fs-16 fw-400"
              >
                {{ currency(program.rate_biweekly) }} {{ $t('paid every two weeks') }}
              </div>
            </template>

            <div
              v-if="!hasRates && program.rate"
              class="fw-400 fs-16"
            >
              {{ currency(program.rate) }} {{ programRateText }}
            </div>

            <div
              v-if="!hasRates && !program.rate"
              class="fs-16 fw-400 c-light-black"
            >
              {{ $t('No cost information') }}
            </div>
          </div>
        </div>

        <div class="d-flex align-start my-3">
          <v-icon
            class="me-3"
            color="primary"
            size="24"
          >
            person_pin
          </v-icon>

          <div
            v-if="!program.contact_name"
            class="d-inline-block fs-16 fw-400 c-light-black"
          >
            {{ $t('No program contact') }}
          </div>

          <div
            v-else
            class="d-inline-block"
          >
            <div class="fs-16 fw-400">Contact {{ program.contact_name }}</div>
            <div
              v-text="
                [program.contact_email, program.contact_phone].filter((item) => item).join(' | ')
              "
              class="fs-16 fw-400"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row
      v-if="program.description"
      dense
    >
      <v-col>
        <div
          @click="description_visible = !description_visible"
          id="manager_program_description"
          class="c-primary underlined pointer fs-16 fw-400"
          tracked
        >
          {{ $t('Description') }}
        </div>
      </v-col>
    </v-row>

    <v-row
      v-if="description_visible && program.description"
      dense
    >
      <v-col>
        <div
          v-text="program.description"
          class="fs-16 fw-400"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import AgeCalculations from '@/shared/mixins/age_calculations';
import HoursChunks from '@/public/components/provider/HoursChunks.vue';
import PublicNotice from '@/shared/components/PublicNotice.vue';
import RouterHelper from '@/shared/mixins/RouterHelper';
import { currency } from '@/plugins/filters';

export default {
  compatConfig: { MODE: 2 },

  components: {
    HoursChunks,
    PublicNotice,
  },

  mixins: [AgeCalculations, RouterHelper],

  props: {
    dashboard: {
      type: Object,
      default: null,
    },
    program: {
      type: Object,
      default: null,
    },
  },

  setup() {
    return { currency };
  },

  data() {
    return {
      description_visible: false,
    };
  },

  computed: {
    ageMaxText() {
      return this.monthsToAgeString(this.program.age_max);
    },

    ageMinText() {
      return this.monthsToAgeString(this.program.age_min);
    },

    enrollmentCount() {
      if (!this.dashboard) return null;

      return (
        this.dashboard.enrollment_program_groups.find(
          (group) => group.program_id === this.program.id,
        )?.count || 0
      );
    },

    gradeMaxText() {
      return this.$t(this.$a.assets.programs.grades[this.program.grade_max].text);
    },

    gradeMinText() {
      return this.$t(this.$a.assets.programs.grades[this.program.grade_min].text);
    },

    hasRates() {
      return (
        this.program.rate_hourly ||
        this.program.rate_daily ||
        this.program.rate_weekly ||
        this.program.rate_monthly ||
        this.program.rate_bimonthly ||
        this.program.rate_biweekly
      );
    },

    programRateText() {
      return this.program.period
        ? [this.$t('paid'), this.$t(this.program.period).toLowerCase()].join(' ')
        : '';
    },

    seatCount() {
      if (!this.dashboard) return null;

      return (
        this.dashboard.enrollment_program_groups.find(
          (group) => group.program_id === this.program.id,
        )?.seats || 0
      );
    },
  },
};
</script>
