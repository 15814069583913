<template>
  <div>
    <video
      ref="videoPlayer"
      :height="height"
      :src="url"
      :width="width"
      class="d-flex align-center mx-auto"
    />

    <v-progress-linear
      v-model="progressBar"
      :width="width"
      class="mb-2 mx-auto"
      readonly
    />

    <v-row
      dense
      no-gutters
    >
      <v-col class="py-0">
        <v-tabs
          bg-color="primary"
          grow
          hide-slider
        >
          <v-tab
            @click="play"
            :disabled="state === 'playing'"
            class="c-white"
            prepend-icon="play_circle"
          >
            {{ $t('Play') }}
          </v-tab>

          <v-tab
            @click="pause"
            :disabled="state === 'paused' || state === 'loaded'"
            class="c-white"
            prepend-icon="pause"
          >
            {{ $t('Pause') }}
          </v-tab>

          <v-tab
            @click="rewind"
            :disabled="state === 'loaded'"
            class="c-white"
            prepend-icon="skip_previous"
          >
            {{ $t('Rewind') }}
          </v-tab>

          <v-tab
            @click="restart"
            :disabled="state === 'loaded'"
            class="c-white"
            prepend-icon="replay"
          >
            {{ $t('Restart') }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { onUnmounted } from 'vue';

const props = defineProps({
  height: {
    type: String,
    default: '600px',
  },
  url: {
    type: String,
    default: null,
  },
  watchedVideoProgress: {
    type: Number,
    default: 0,
  },
  width: {
    type: String,
    default: '100%',
  },
});

const emit = defineEmits(['complete', 'updateVideoProgress']);

const currentTime = ref(0);
const duration = ref(null);
const progressBar = ref(0);
const state = ref(null);
const videoPlayer = ref(null);
const videoProgressInterval = ref(null);

watch(props.url, () => (state.value = null), { immediate: true });

function addEventListeners() {
  videoPlayer.value.addEventListener('ended', markCompleted);
  videoPlayer.value.addEventListener('timeupdate', handleTimeUpdate);
}

function beginUpdatingVideoProgress() {
  videoProgressInterval.value = setInterval(() => {
    if (state.value === 'playing') {
      const progress = parseInt((currentTime.value / duration.value) * 100, 10);
      emit('updateVideoProgress', progress);

      if (progress === 100) {
        state.value = 'paused';
        clearInterval(videoProgressInterval.value);
      }
    }
  }, 5000);
}

function handleTimeUpdate() {
  currentTime.value = videoPlayer.value.currentTime;
  progressBar.value = (currentTime.value / duration.value) * 100;
}

function load() {
  beginUpdatingVideoProgress();
  videoPlayer.value.addEventListener('loadedmetadata', setDuration);
}

function markCompleted() {
  emit('complete');
}

function maybeRemoveVideoListeners() {
  if (videoPlayer.value) removeEventListeners();
}

function pause() {
  videoPlayer.value.pause();
  state.value = 'paused';
}

function play() {
  videoPlayer.value.currentTime = currentTime.value;
  videoPlayer.value.play();
  state.value = 'playing';
  removeEventListeners();
  addEventListeners();
}

function removeEventListeners() {
  videoPlayer.value.removeEventListener('timeupdate', handleTimeUpdate);
  videoPlayer.value.removeEventListener('ended', markCompleted);
  videoPlayer.value.removeEventListener('loadedmetadata', setDuration);
}

function restart() {
  videoPlayer.value.currentTime = 0;
  currentTime.value = 0;
  videoPlayer.value.play();
  state.value = 'playing';
}

function rewind() {
  const goBack = Math.min(videoPlayer.value.currentTime, 10);
  videoPlayer.value.currentTime -= goBack;
  currentTime.value = videoPlayer.value.currentTime;
  videoPlayer.value.play();
  state.value = 'playing';
}

function setDuration() {
  duration.value = videoPlayer.value.duration;
  currentTime.value = duration.value * (props.watchedVideoProgress / 100);
  progressBar.value = (currentTime.value / duration.value) * 100;

  if (!state.value) state.value = 'loaded';
}

onMounted(load);
onUnmounted(maybeRemoveVideoListeners);
</script>
