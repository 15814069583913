<template>
  <div
    class="pb-0 mb-0"
    style="overflow-anchor: none"
  >
    <v-img
      v-if="type === 'image'"
      :src="url"
      :width="width"
      class="mx-auto"
      height="80vh"
    />

    <VideoPlayer
      v-else-if="type === 'video'"
      @complete="$emit('complete')"
      @update-video-progress="$emit('updateVideoProgress', $event)"
      v-bind="attrsOmitDataPrefix"
      :url="url"
      :watched-video-progress="watchedVideoProgress"
      type="video/mp4"
    />

    <object
      v-else-if="type === 'pdf'"
      :data="url"
      :width="width"
      height="600px"
      type="application/pdf"
    />
  </div>
</template>

<script setup>
import attrsOmitDataPrefix from '@/shared/utils/filteredAttrs';
import VideoPlayer from '@/shared/components/VideoPlayer.vue';

const props = defineProps({
  url: {
    type: String,
    default: null,
  },
  watchedVideoProgress: {
    type: Number,
    default: 0,
  },
});

defineEmits(['complete', 'updateVideoProgress']);

const width = ref('100%');

const type = computed(() => {
  if (!props.url) return null;

  const contentUrl = props.url.toLowerCase();
  const videoExtensions = ['.mp4'];
  const pdfExtensions = ['.pdf'];
  const imageExtensions = ['.png', '.jpg', '.jpeg', '.svg'];

  if (videoExtensions.some((extension) => contentUrl.endsWith(extension))) return 'video';
  if (pdfExtensions.some((extension) => contentUrl.endsWith(extension))) return 'pdf';
  if (imageExtensions.some((extension) => contentUrl.endsWith(extension))) return 'image';

  return null;
});
</script>
