<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card
    id="contact"
    :border="$role == 'specialist'"
    flat
    tile
  >
    <v-card-title class="tab-title d-flex">
      <v-btn
        v-if="$vuetify.display.smAndDown"
        @click="$emit('back')"
        class="me-1 c-black"
        variant="text"
        icon
      >
        <v-icon
          icon="chevron_left"
          size="28"
        />
      </v-btn>
      <h2 class="v-card-title py-0 px-0">
        {{ $t('Contact information') }}
      </h2>
    </v-card-title>

    <v-divider />

    <v-card-text
      v-if="business"
      class="pb-0"
    >
      <div class="fs-16 c-black">
        <span> {{ `${$t('This provider is a location of')}` }} - </span>
        <router-link
          :to="{
            name: 'BusinessShow',
            params: { businessId: business?.id || provider.business_id },
          }"
          class="underlined"
          target="_blank"
        >
          {{ business.name || $t('Unnamed business') }}
        </router-link>
      </div>
    </v-card-text>

    <StaticMap
      :height="250"
      :latitude="provider.latitude"
      :longitude="provider.longitude"
      :width="525"
      class="rounded"
    />

    <v-card-text>
      <v-row
        v-for="field in $store.state.schemas[provider.schema_id].meta.contact.prepend"
        :key="field"
      >
        <CustomField
          :field="field"
          :model-value="provider"
          :readonly="false"
          :schema-id="provider.schema_id"
          class="fs-16"
          mode="edit"
          show-icon="true"
          global
        />
      </v-row>
      <v-row>
        <LabeledTextfield
          v-model="provider.name"
          :schema-id="schemaId"
          aria-label="Business name or DBA"
          field="name"
          global
        />
        <LabeledTextfield
          v-model="provider.email"
          :schema-id="schemaId"
          aria-label="Email"
          cols="6"
          field="email"
          global
        />
        <LabeledTextfield
          v-model="provider.phone"
          :schema-id="schemaId"
          aria-label="Phone"
          cols="6"
          data-cy="edit_phone"
          field="phone"
          global
        />
        <LabeledTextfield
          v-model="provider.website"
          :schema-id="schemaId"
          aria-label="Website"
          field="website"
          global
        />
        <LabeledTextfield
          v-model="provider.address1"
          :schema-id="schemaId"
          aria-label="Address"
          cols="12"
          field="address1"
          global
        />
        <LabeledTextfield
          v-model="provider.city"
          :schema-id="schemaId"
          aria-label="City"
          cols="6"
          field="city"
          global
        />
        <LabeledSimpleSelect
          v-model="provider.state"
          :items="$a.assets.states"
          :schema-id="schemaId"
          aria-label="State"
          cols="6"
          field="state"
          placeholder="Select one"
          global
        />
        <LabeledTextfield
          v-model="provider.zip"
          :schema-id="schemaId"
          aria-label="Zip"
          cols="6"
          field="zip"
          global
        />
        <LabeledAutocomplete
          v-if="counties.length > 0"
          v-model="provider.county"
          :items="counties"
          :schema-id="schemaId"
          aria-label="County"
          cols="6"
          field="county"
          global
        />
        <LabeledTextfield
          v-else
          v-model="provider.county"
          :schema-id="schemaId"
          aria-label="County"
          cols="6"
          field="county"
          global
        />
        <LabeledTextfield
          v-model="provider.facebook_url"
          :schema-id="schemaId"
          aria-label="Link to Facebook"
          field="facebook_url"
          global
        />
        <LabeledTextfield
          v-model="provider.google_url"
          :schema-id="schemaId"
          aria-label="Link to Google"
          field="google_url"
          global
        />
        <LabeledTextfield
          v-model="provider.yelp_url"
          :schema-id="schemaId"
          aria-label="Link to Yelp"
          field="yelp_url"
          global
        />
        <LabeledTextfield
          v-model="provider.mailing_address1"
          :schema-id="schemaId"
          aria-label="Mailing street address"
          cols="12"
          field="mailing_address1"
          global
        />
        <LabeledTextfield
          v-model="provider.mailing_address2"
          :schema-id="schemaId"
          aria-label="Mailing apt, suite, etc"
          cols="6"
          field="mailing_address2"
          global
        />
        <LabeledTextfield
          v-model="provider.mailing_city"
          :schema-id="schemaId"
          aria-label="Mailing city"
          cols="6"
          field="mailing_city"
          global
        />
        <LabeledSimpleSelect
          v-model="provider.mailing_state"
          :items="$a.assets.states"
          :schema-id="schemaId"
          aria-label="Mailing state"
          cols="6"
          field="mailing_state"
          placeholder="Select one"
          global
        />
        <LabeledTextfield
          v-model="provider.mailing_zip"
          :schema-id="schemaId"
          aria-label="Mailing zip"
          cols="6"
          field="mailing_zip"
          global
        />
      </v-row>

      <v-row
        v-for="(field, index) in $store.state.schemas[provider.schema_id].meta.contact.append"
        :key="index"
      >
        <CustomField
          :field="field"
          :model-value="provider"
          :readonly="false"
          :schema-id="provider.schema_id"
          class="fs-16"
          mode="edit"
          show-icon="true"
          global
        />
      </v-row>

      <v-row class="mt-6">
        <LabeledTextfield
          v-model="provider.latitude"
          :schema-id="schemaId"
          aria-label="Latitude"
          field="latitude"
          global
        />
        <LabeledTextfield
          v-model="provider.longitude"
          :schema-id="schemaId"
          aria-label="Longitude"
          field="longitude"
          global
        />
      </v-row>

      <div class="c-black fw-600 fs-16 mt-8 mb-4">
        {{ $t('Primary contact') }}
      </div>
      <v-row>
        <LabeledTextfield
          v-model="provider.contact_name"
          :schema-id="schemaId"
          aria-label="Contact name"
          field="contact_name"
          global
        />
        <LabeledTextfield
          v-model="provider.contact_phone"
          :schema-id="schemaId"
          aria-label="Contact phone"
          field="contact_phone"
          global
        />
        <LabeledTextfield
          v-model="provider.contact_email"
          :schema-id="schemaId"
          aria-label="Contact email"
          field="contact_email"
          global
        />
        <LabeledTextfield
          v-if="$role == 'specialist'"
          v-model="provider.owner_email"
          :schema-id="schemaId"
          aria-label="Manager email"
          field="owner_email"
          global
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import CustomField from '@/shared/components/CustomField.vue';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import StaticMap from '@/shared/components/provider/StaticMap.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    CustomField,
    LabeledAutocomplete,
    LabeledSimpleSelect,
    LabeledTextfield,
    StaticMap,
  },

  props: {
    business: {
      type: Object,
      default: null,
    },
    provider: {
      type: Object,
      default: null,
    },
  },

  emits: ['back', 'progress'],

  data() {
    return {
      schemaId: this.provider.schema_id,
    };
  },

  computed: {
    progress() {
      const complete =
        this.provider.address1 &&
        this.provider.city &&
        this.provider.zip &&
        this.provider.email &&
        this.provider.phone;
      return complete ? 100 : 0;
    },

    counties() {
      return (
        this.$store.state.schemas[this.provider.schema_id].definition.properties.county?.enum || []
      );
    },
  },

  watch: {
    progress: {
      immediate: true,
      handler(newVal) {
        this.$emit('progress', newVal);
      },
    },
  },
};
</script>
