export default {
  computed: {
    currentStage() {
      if (this.schema) {
        return this.schema.stages[this.currentStageIndex] || {};
      }
      return {};
    },

    currentStageIndex() {
      if (this.schema && this.assessment) {
        const index = this.schema.stages.findIndex((stage) => this.assessment.stage_id == stage.id);
        if (index == -1) {
          return 0;
        }
        return index;
      }
      return 0;
    },

    queriedAttachments() {
      if (this.documentQuery) {
        return this.attachments.filter((attachment) =>
          attachment.file.metadata.filename
            .toLowerCase()
            .includes(this.documentQuery.toLowerCase()),
        );
      }
      return this.attachments;
    },
  },

  data() {
    return {
      currentStageId: null,
    };
  },

  methods: {
    selectSheet(ws) {
      this.$router.push({ query: { worksheet_id: ws.id } });
    },

    validateQuestions(allQuestions, callback) {
      const assertions = allQuestions.map((question) => {
        if (question.conditions.length > 0) {
          return {
            assertion: {
              conditions: question.conditions,
              value: this.selectedSheet ? this.selectedSheet : this.assessment,
            },
          };
        }
        return {
          assertion: {
            conditions: [],
            value: {},
          },
        };
      });

      this.api.public_api.assertion.bulkCreate(assertions, (resp) => {
        const { results } = resp.data;
        allQuestions.forEach((question, index) => {
          question.valid = results[index].result;
        });
        this.questions = allQuestions.filter((question) => question.valid);

        if (callback) callback();
      });
    },
  },
};
