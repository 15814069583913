<template>
  <ResourceDialog
    @close="close"
    @save="submit"
    @save:draft="save"
    ref="dialog"
    :processing="processing"
    :save-button-disabled="!!operatingLog?.submitted_at"
    :title="`Submit ${terms.attendance}`"
    save-button-text="Submit"
    closeable
    draftable
    fullscreen
  >
    <template #form>
      <template v-if="operatingLog">
        <QuestionSet
          v-model="operatingLog"
          :attachment-owner-id="attachmentOwnerId()"
          :attachment-owner-type="'OperatingLog'"
          :questions="validPublishedQuestions"
          :readonly="!!operatingLog.submitted_at"
          :schema="schema.definition"
          expanded
          flat
          hide-actions
          outlined-sections
          paddingless
          very-dense
        />

        <div
          v-for="(question, index) in validVerificationQuestions"
          :key="['verification', index].join('-')"
        >
          <FormQuestion
            :schema="schema.definition"
            :subtitle="question.verification_subtitle"
            :title="question.verification_title"
            hide-actions
            very-dense
          >
            <AttachmentUploader
              @uploaded="attachments.push($event)"
              :ref="['uploader', question.id].join('')"
              :owner="{
                type: 'OperatingLog',
                id: operatingLog.id,
                tag: question.id,
                tags: [operatingLog.id, question.id],
              }"
              class="mb-4"
            />

            <AttachmentList
              @delete="loadAttachments"
              :attachments="attachments.filter((attachment) => attachment.tag == question.id)"
              class="mb-6"
            />
          </FormQuestion>
        </div>
      </template>
    </template>

    <template #component-actions>
      <template v-if="userCanUnsubmit()">
        <v-btn
          @click="unsubmitAttendance"
          :disabled="unsubmitButtonDisabled()"
          :loading="processing"
          :ripple="false"
          class="focus-very-visible px-4"
          color="primary"
          data-cy="unsubmit-button"
          size="x-large"
          variant="outlined"
        >
          <span>{{ $t('Unsubmit') }}</span>
        </v-btn>
      </template>
    </template>
  </ResourceDialog>
</template>

<script>
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { ROLES } from '@/shared/assets/constants';

import API from '@/shared/mixins/api';
import Questionable from '@/shared/mixins/questionable';
import RouterHelper from '@/shared/mixins/RouterHelper';
import Terms from '@/shared/mixins/terms';

export default {
  compatConfig: { MODE: 2 },

  components: {
    AttachmentList,
    AttachmentUploader,
    FormQuestion,
    QuestionSet,
    ResourceDialog,
  },

  mixins: [API, Questionable, RouterHelper, Terms],

  props: {
    program: {
      type: Object,
      default: null,
    },
  },

  emits: ['change'],

  data() {
    return {
      attachments: [],
      operatingLog: null,
      processing: false,
      schema: Object.values(this.$store.state.schemas).find(
        (schema) => schema.data_type === 'OperatingLog',
      ),
      ROLES,
    };
  },

  computed: {},

  watch: {
    '$route.query.action': {
      immediate: true,
      handler(newVal) {
        if (newVal === 'editOperatingLog') this.open();
      },
    },
  },

  created() {
    this.ownerId = this.schema?.id;
    this.ownerType = 'Schema';
    this.loadQuestions();
  },

  methods: {
    attachmentOwnerId() {
      return this.operatingLog.id;
    },

    async close() {
      this.$refs.dialog?.close();
      await this.updateQuery({ action: null });
    },

    unsubmitAttendance() {
      return !this.unsubmitButtonDisabled() && this.userCanUnsubmit() && this.unsubmit();
    },

    unsubmitButtonDisabled() {
      return !this.operatingLog?.submitted_at;
    },

    userCanUnsubmit() {
      return this.$role === ROLES.SPECIALIST;
    },

    async loadAttachments() {
      const params = {
        owner_type: 'OperatingLog',
        owner_id: this.operatingLog.id,
      };
      const resp = await this.api.attachment.index(params);
      this.attachments = resp.data;
    },

    async loadOperatingLog(operatingLogId) {
      const resp = await this.api.operating_log.get(operatingLogId);
      this.operatingLog = resp.data;
    },

    async open() {
      await this.loadOperatingLog(this.$route.query.operatingLogId);
      await this.loadAttachments();

      this.$refs.dialog.open();
    },

    async save() {
      this.processing = true;
      const params = { custom: this.operatingLog.custom };
      const resp = await this.api.operating_log.update(this.operatingLog.id, params);
      this.processing = false;
      if (resp?.status !== 200) return false;

      this.$eventBus.$emit('chime', 'Saved');
      return true;
    },

    async submit() {
      this.processing = true;
      const params = { submitted: true, custom: this.operatingLog.custom };
      const resp = await this.api.operating_log.update(this.operatingLog.id, params);
      this.processing = false;
      if (resp?.status !== 200) return false;

      this.$eventBus.$emit('chime', 'Submitted');
      this.operatingLog = resp.data;
      this.close();
      this.$emit('change', this.operatingLog);
      return true;
    },

    async unsubmit() {
      this.processing = true;
      const params = { submitted: false };
      const resp = await this.api.operating_log.update(this.operatingLog.id, params);
      this.processing = false;
      if (resp?.status !== 200) return false;

      this.$eventBus.$emit('chime', 'Unsubmitted');
      this.operatingLog = resp.data;
      this.$emit('change', this.operatingLog);
      return true;
    },
  },
};
</script>
