<template>
  <div
    ref="editor"
    class="w-100pc br-0 mb-3"
  />
</template>

<script setup>
import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css';

const props = defineProps({
  initialValue: {
    type: String,
    default: null,
  },
  initialEditType: {
    type: String,
    default: 'markdown',
  },
  placeholder: {
    type: String,
    default: null,
  },
});

let editor = ref();
let editorInstance = ref();

onMounted(() => {
  loadEditor();
});

defineExpose({
  getMarkdown,
  reset,
});

// https://github.com/nhn/tui.editor/tree/master/apps/editor#default-options
function loadEditor() {
  setTimeout(() => {
    editorInstance.value = new Editor({
      el: editor.value,
      height: '300px',
      initialEditType: props.initialEditType,
      initialValue: props.initialValue || '',
      previewStyle: 'vertical',
      placeholder: props.placeholder,
    });
  }, 100);
}

function getMarkdown() {
  return editorInstance.value.getMarkdown().replaceAll('\\_', '_');
}

function reset() {
  editorInstance.value.reset();
}
</script>
